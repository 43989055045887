import React, { useState, useEffect } from "react";
import axios from "axios";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Swal from "sweetalert2";

import { selectUserJWTToken } from "../../redux/user/user.selector";
import { selectProductTypes, selectProductGroups } from "../../redux/settings/settings.selector";
import { setConfigStart } from "../../redux/settings/settings.action";

import { SITE_BACKEND_API_URL, SITE_BACKEND_FULL_URL } from "../../server";
import { COMMON_DATE_RANGE, DateRangeInterface, COMMON_DATE_PROP, DEFAULT_PAGE_SIZE } from "../Common";
import { StringKeyValuePair, InputChangeEvent } from "../Common/common-types"

import { AdminTable } from "../Table";
import TableQueries from "../Table/table-queries.component";
import { Loader, TableColumnProp, CSVHeaderInterface } from "../Common";
import CustomMultiSelectCheckbox from "../Common/custom-multiselect-checkbox-component";
import CustomCSVLink from "../Common/custom-csv-link-component";

import { Checkbox } from "@material-ui/core";
import { DownloadButton } from "../Common";

import {
  SubmissionContainer,
  TabArea,
  TabHeader,
  TabSubLink,
  TabSubTitle,
  TabTitle,
  FilterToolbar,
  ExportToolbar,
} from "../SharedStyle/styled";

import { FormControlLabel } from "@material-ui/core";

enum ExportType { 
  ByArtist = "ByArtist", 
  ByOrder = "ByOrder", 
  ByPaypal = "ByPaypal" 
};

const TABLE_COLUMNS: TableColumnProp[] = [
  {
    Header: "Order Ids",
    accessor: "order_ids",
    isHidden: true,
  },
  {
    Header: "Artist Paypal Email",
    accessor: "paypal_email",
    filter: "text",
    className: 'cell-center',
    disableSortBy: true,
  },
  {
    Header: "Unpaid Gallery",
    accessor: "unpaid_gallery",
    disableFilters: true,
    className: 'cell-center',
    disableSortBy: true,
  },
  {
    Header: "Unpaid ODAD",
    accessor: "unpaid_odad",
    disableFilters: true,
    className: 'cell-center',
    disableSortBy: true,
  },
  {
    Header: "Unpaid Weekly",
    accessor: "unpaid_weekly",
    disableFilters: true,
    className: 'cell-center',
    disableSortBy: true,
  },

  {
    Header: "Paid Gallery",
    accessor: "paid_gallery",
    disableFilters: true,
    className: 'cell-center',
    disableSortBy: true,
  },
  {
    Header: "Paid ODAD",
    accessor: "paid_odad",
    disableFilters: true,
    className: 'cell-center',
    disableSortBy: true,
  },
  {
    Header: "Paid Weekly",
    accessor: "paid_weekly",
    disableFilters: true,
    className: 'cell-center',
    disableSortBy: true,
  },
  /*
  { 
    Header: "Paid Paypal Commission",
    accessor: "paid_paypal_commission",
    disableFilters: true,
    className: 'cell-center',
    disableSortBy: true,
  },
  */
  { 
    Header: "Donation Amount",
    accessor: "total_tip_amount",
    disableFilters: true,
    className: 'cell-center',
    disableSortBy: true,
  },
  { 
    Header: "Negative Commissions",
    accessor: "negative_commissions",
    disableFilters: true,
    className: 'cell-center',
    disableSortBy: true,
  },
  { 
    Header: "Negative Tip",
    accessor: "negative_tip",
    disableFilters: true,
    className: 'cell-center',
    disableSortBy: true,
  },
  { 
    Header: "Paypal Commission",
    accessor: "unpaid_paypal_commission",
    disableFilters: true,
    className: 'cell-center',
    disableSortBy: true,
  },
  {
    Header: "Number of Orders",
    accessor: "number_of_orders",
    disableFilters: true,
    className: 'cell-center',
    disableSortBy: true,
  },
  {
    Header: "Number of Designs",
    accessor: "number_of_titles",
    disableFilters: true,
    className: 'cell-center',
    disableSortBy: true,
  },
  {
    Header: "Commission Owed",
    accessor: "commission_owed",
    disableFilters: true,
    className: 'cell-center',
    disableSortBy: true,
  }
];

const csvPaypalHeaders: CSVHeaderInterface[] = [
  {
    'key': 'paypal_email',
    'label': 'Paypal Email'
  },
  // {
  //   'key': 'negative_commissions',
  //   'label': 'Negative Commissions'
  // },
  {
    'key': 'commission_owed',
    'label': 'Commission Owed'
  },
  {
    'key': 'currency',
    'label': 'Currency'
  },
  {
    'key': 'id',
    'label': 'ID'
  },
  {
    'key': 'details',
    'label': 'Details'
  },
];

interface SearchFilterInterface {
    startDate: Date|null;
    endDate: Date|null;
    cancelStartDate: Date|null;
    cancelEndDate: Date|null;
    productTypes: string[];
    productGroups: string[];
    paymentStatuses: string[];
    checkedExcludeTags: boolean;
    paypal_email: string;
    pageSize: number;
    pageIndex: number;
    includeCancelReturns: boolean;
}

const PAYMENT_STATUSES = ["Paid", "Unpaid"];

const TealCheckbox = (props: any) => <Checkbox color="default" {...props} />;

const AdminCommissionsByArtist = (props: any) => {
    
    const [tableData, setTableData] = useState(null);
    const [tableColumns, setTableColumns] = useState(TABLE_COLUMNS);
    const [isCommLoading, setIsCommLoading] = useState<boolean>(false);
    const [showTableLoader, setTableLoader] = useState(false);
    const [pageCount, setPageCount] = React.useState(0);
    const [totalRecordCount, setTotalRecordCount] = React.useState(0);
    const [isFilterInitialized, setIsFilterInitialized] = React.useState(false);
    
    const [filters, setFilters] = useState<SearchFilterInterface>({
        startDate: COMMON_DATE_RANGE.startDate,
        endDate: COMMON_DATE_RANGE.endDate,
        cancelStartDate: COMMON_DATE_RANGE.startDate,
        cancelEndDate: COMMON_DATE_RANGE.endDate,
        productTypes: [],
        productGroups: [],
        paymentStatuses: [],
        checkedExcludeTags: false,
        paypal_email: "",
        pageSize: DEFAULT_PAGE_SIZE,
        pageIndex: 0,
        includeCancelReturns: false,
    });

    const [csvHeaders, setCsvHeaders] = useState<CSVHeaderInterface[]>([]);
    const [csvOrdersHeaders, setCsvOrdersHeaders] = useState<CSVHeaderInterface[]>([]);
  
    const { token, productTypes, productGroups, setConfigStartAction } = props;
  
    // useEffect(() => {
    //     setConfigStartAction();
    // }, []);

    useEffect(() => {
      if(!isFilterInitialized) { // Don't load report by default to avoid performace issue
        console.log("useEffect filter initialized (Only first time when filter load).");
        setIsFilterInitialized(true);
      } else {
        console.log("useEffect filter changed.");
        _getAllCommissions(filters);
      }
    }, [filters]);

    const updateTableColumns = (paymentStatuses: String[]) => {
      const unWantedComColumns = filters.includeCancelReturns ? [] : ['negative_commissions', 'negative_tip'];

      const paidColumns = ['paid_gallery', 'paid_odad', 'paid_weekly', 'paid_paypal_commission'];
      const unpaidColumns = ['unpaid_gallery', 'unpaid_odad', 'unpaid_weekly', 'unpaid_paypal_commission'];

      // Hide unwanted table columns
      const unWantedPaymentColumns = paymentStatuses.length ? (paymentStatuses[0] === "Paid" ? unpaidColumns : paidColumns) : [];
      const unWantedColumns = [...unWantedPaymentColumns, ...unWantedComColumns];
      const newTableColumns = TABLE_COLUMNS.filter(column => !unWantedColumns.includes(column.accessor));
      
      setTableColumns(newTableColumns);

      // Generate header for CSV by Artists
      const newCsvHeaders = newTableColumns.filter(c => !c.isHidden).map(col => ({label: col.Header, key: col.accessor}))

      const newCsvByArtistHeaders = [...newCsvHeaders]; // Make independent copy of newCsvHeaders

      // console.log(newCsvHeaders);
      setCsvHeaders(newCsvByArtistHeaders); 

      // Genarate header for CSV by Orders, Add order id column
      newCsvHeaders.splice(1, 0, {label: "Order Id", key: "order_id" });

      const paypalColumns = ['paid_paypal_commission', 'unpaid_paypal_commission'];
      
      // Remove "paypal_commission" column for CSV by Order
      const newCsvByOrderHeaders = newCsvHeaders.filter(column => !paypalColumns.includes(column.key));

      // console.log("newCsvByOrderHeaders");
      // console.log(newCsvByOrderHeaders);
      setCsvOrdersHeaders(newCsvByOrderHeaders);
    }

    const _getAllCommissions = async (dataFilters: SearchFilterInterface) => {

      console.log("_getAllCommissions called.");

        try {

          setTableLoader(true);

          const { startDate, endDate } = getDateParams();
          const requetData = {...dataFilters, startDate, endDate };
            
            const {
                data: { commissionsByArtist, totalRecordCount },
            } = await axios({
                url: SITE_BACKEND_API_URL+"/admin/commissions/listByArtist",
                method: "POST",
                data: requetData,
                headers: { Authorization: `JWT ${token}` },
            });

            updateTableColumns(dataFilters.paymentStatuses);
            setTableData(commissionsByArtist);
            // setCsvBodyData(commissionsByArtist);
            // setCsvOrdersBodyData(commissionsByOrder);
            // setCsvPaypalBodyData(commissionsByPaypal);

            setIsCommLoading(false);
            setTableLoader(false);

            setPageCount(Math.ceil(totalRecordCount / filters.pageSize));
            setTotalRecordCount(totalRecordCount);

            return commissionsByArtist;
        } catch (error) {
          setTableLoader(false);
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Something went wrong. Please try again.",
          });
          return;
        }
    };

    const getDateParams = () => {
      const startDate = filters.startDate !== null ? getFormatedDate(filters.startDate) : '';
      const endDate = filters.endDate !== null ? getFormatedDate(filters.endDate) : '';

      return { startDate, endDate };
    }

    const getFormatedDate = (date: Date) : string => {

      if(!(date instanceof Date)) {
        return date;
      }
  
      const monthNumber = (date.getMonth() + 1);
      const dateNumber = date.getDate();
  
      const monthSeperater = (monthNumber < 10) ? '-0' : '-';
      const dateSeperater = (dateNumber < 10) ? '-0' : '-';
      return date.getFullYear() + monthSeperater + monthNumber + dateSeperater + dateNumber;
    }
    
    const handleDateFilter = async (dateRange: DateRangeInterface | null) => {
        try {

          const newFilters = filters;

          if(dateRange !== null) {
            const { startDate, endDate } = dateRange;
    
            COMMON_DATE_RANGE.startDate = new Date(startDate + " 00:00:00");
            COMMON_DATE_RANGE.endDate = new Date(endDate + " 00:00:00");
    
            newFilters.startDate = startDate;
            newFilters.endDate = endDate;
    
          } else {
            newFilters.startDate = null;
            newFilters.endDate = null;
          }

          setFilters({...newFilters});
          
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Something went wrong. Please try again.",
          });
        }
    }

    const handleCancelDateFilter = async (dateRange: DateRangeInterface | null) => {
      try {

        const newFilters = filters;

        if(dateRange !== null) {
          const { startDate, endDate } = dateRange;
  
          COMMON_DATE_RANGE.startDate = new Date(startDate + " 00:00:00");
          COMMON_DATE_RANGE.endDate = new Date(endDate + " 00:00:00");
  
          newFilters.cancelStartDate = startDate;
          newFilters.cancelEndDate = endDate;
  
        } else {
          newFilters.cancelStartDate = null;
          newFilters.cancelEndDate = null;
        }

        setFilters({...newFilters});
        
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Something went wrong. Please try again.",
        });
      }
  }
      
    const handleProductTypesChange = (event: any) => {
      setFilters({...filters, productTypes: event.target.value});
    }

    const handleProductGroupsChange = (event: any) => {
      setFilters({...filters, productGroups: event.target.value});
    }

    const handlePaymentStatusChange = (event: any) => {
      setFilters({...filters, paymentStatuses: event.target.value});
    }
    
    const handleCheckboxChange = (event: InputChangeEvent) => {
      setFilters({...filters, checkedExcludeTags: event.target.checked});
    };    

    const handleIncludeCancelChange = (event: InputChangeEvent) => {
      setFilters({...filters, includeCancelReturns: event.target.checked});
    };

    const globleSearchHandler = (globleFilters: any, pageIndex: number, pageSize: number) => {
      console.log("globleSearchHandler: ");
  
      const newFiltersList: StringKeyValuePair = resetFilter();
  
      globleFilters.forEach((filter: any) => {
        newFiltersList[filter.id] = filter.value.trim();   
      });
  
      setFilters({...filters, ...newFiltersList, pageIndex, pageSize});
    }

    const resetFilter = () => {
      return {
        paypal_email: "",
      }
    }
    
    const onTableLoading = (isLoading: boolean) => {
      setTableLoader(isLoading);
    }

    const reloadData = () => {
      _getAllCommissions(filters);
    }

    const markedAllPaymentStatus = (isPaid: boolean) => {
      Swal.fire({
        title: "Are you sure?",
        text: `All listed orders will be marked as ${isPaid ? "Paid" : "Unpaid"}.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonColor: "#d33",
      }).then(async (response) => {
        if (response.value) { // Confirmed
  
          setTableLoader(true);
  
          const { startDate, endDate } = getDateParams();
          const filtersData = {...filters, startDate, endDate };
          
          try {

          // // update payment status
           await axios.put(SITE_BACKEND_API_URL+`/admin/commissions/updatePaymentStatusByArtist`, { filtersData, isPaid }, {
              headers: {
                Authorization: `JWT ${token}`,
              },
            }
          );
  
          setTableLoader(false);
          Swal.fire({
            icon: "success",
            title: `Commissions marked as ${isPaid ? "Paid" : "Unpaid"}`,
          });
  
          // Reload table
          reloadData();
  
        } catch (error) {
          
          setTableLoader(false);
          
          Swal.fire({
            icon: "error",
            title: "Sorry, Something Went Wrong",
          });
        }
  
        }
      });
    };

    const exportCSV = async (exportType: ExportType) => {

        const { startDate, endDate } = getDateParams();

        setIsCommLoading(true);

        let csvHeaderParam = csvHeaders;
        switch(exportType) {
          case ExportType.ByArtist : csvHeaderParam = csvHeaders; break;
          case ExportType.ByOrder : csvHeaderParam = csvOrdersHeaders; break;
          case ExportType.ByPaypal : csvHeaderParam = csvPaypalHeaders; break;
        }

        const requetData = {...filters, startDate, endDate, exportType, csvHeaders: csvHeaderParam };
        
        const {
            data: { csvPath },
        } = await axios({
            url: SITE_BACKEND_API_URL+"/admin/commissions/exportCSV",
            method: "POST",
            data: requetData,
            headers: { Authorization: `JWT ${token}` },
        });

        window.open(SITE_BACKEND_FULL_URL+"/"+csvPath, '_blank');

        setIsCommLoading(false);
    }

    return (
        <SubmissionContainer style={{ maxWidth: "86vw", width: "98%" }}>
          <TabHeader>
            <TabSubLink to={`/admin/commissions`}>
              <TabSubTitle>Commissions by Order</TabSubTitle>
            </TabSubLink>
            <TabTitle>Commissions by Artist</TabTitle>
            <TabSubLink to={`/admin/commissions/payouts`}>
              <TabSubTitle>Payouts</TabSubTitle>
            </TabSubLink>
            <TabSubLink to={`/admin/commissions/errors`}>
              <TabSubTitle>Commissions Errors</TabSubTitle>
            </TabSubLink>
          </TabHeader>
          <TabArea style={{ padding: "55px 20px" }}>

          <FilterToolbar style={{ margin: "auto" }}>
              <FormControlLabel
                  control={
                    <TealCheckbox
                      checked={filters.includeCancelReturns}
                      onChange={handleIncludeCancelChange}
                      name="includeCancelChange"
                    />
                  }
                  label="Include Cancels/Returns"
                  style={{ margin: "auto" }}
                />
            </FilterToolbar>

            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div>
                <h3 style={{textAlign: "center"}}>Ordered Date</h3>
                <TableQueries
                  handleDateFilter={handleDateFilter}
                  globalStartDate={COMMON_DATE_RANGE.startDate}
                  globalEndDate={COMMON_DATE_RANGE.endDate}
                  isDateOpened={COMMON_DATE_PROP.isDateOpened}
                  note="Note: Default is today."
                  isDateClearable={true}
                />
              </div>

            {filters.includeCancelReturns ? (
              <div>
                <h3 style={{textAlign: "center"}}>Cancels/Returns Date</h3>
                <TableQueries
                    handleDateFilter={handleCancelDateFilter}
                    globalStartDate={COMMON_DATE_RANGE.startDate}
                    globalEndDate={COMMON_DATE_RANGE.endDate}
                    isDateOpened={COMMON_DATE_PROP.isDateOpened}
                    note="Note: Default is today."
                    isDateClearable={true}
                  />
              </div>) 
              : null }
            </div>

            {totalRecordCount > 0 ? (
              <ExportToolbar>
                  <div style={{ marginRight: "50px" }}>
                    <DownloadButton title="Export CSV" handleDownload={() => exportCSV(ExportType.ByArtist)}/>
                  </div>

                  <div style={{ marginRight: "50px" }}>
                    <DownloadButton title="Export Orders" handleDownload={() => exportCSV(ExportType.ByOrder)}/>
                  </div>

                  <div>
                    <DownloadButton title="Paypal Export" handleDownload={() => exportCSV(ExportType.ByPaypal)}/>
                  </div>
              </ExportToolbar>
            ) : null }

            <FilterToolbar>
                <CustomMultiSelectCheckbox 
                  label="Product Type" 
                  list={productTypes} 
                  inputName="productTypes" 
                  selectedValues={filters.productTypes} 
                  onChange={handleProductTypesChange} 
                />

                <CustomMultiSelectCheckbox 
                  label="Product Group" 
                  inputName="productGroups" 
                  list={productGroups} 
                  selectedValues={filters.productGroups} 
                  onChange={handleProductGroupsChange} 
                />

                <CustomMultiSelectCheckbox 
                  label="Payment Status" 
                  list={PAYMENT_STATUSES} 
                  inputName="paymentStatuses" 
                  selectedValues={filters.paymentStatuses} 
                  onChange={handlePaymentStatusChange} 
                />
              
                <FormControlLabel
                    control={
                      <TealCheckbox
                        checked={filters.checkedExcludeTags}
                        onChange={handleCheckboxChange}
                        name="checkedExcludeTags"
                      />
                    }
                    label="Exclude Tags"
                    style={{ }}
                  />
            </FilterToolbar>

            {isCommLoading || (tableData === null && showTableLoader) ? ( <Loader/> ) : (
              tableData === null ? ( 
                <div>
                  <hr/>
                  <p style={{ textAlign: 'center', fontSize: "20px", padding: "20px" }}>
                    Please select date range to load report.
                  </p> 
                </div>
              ) :
              <AdminTable
                columns={tableColumns}
                reloadData={reloadData}
                data={tableData}
                token={token}
                showToolbar={true}
                isSelectableRows={true}
                isSortingEnabled={true}
                isMainCommissionTable={false}
                isManualSearch={true}
                globleSearchHandler={globleSearchHandler}
                onTableLoading={onTableLoading}
                showTableLoader={showTableLoader}
                pageCount={pageCount}
                pageIndex={filters.pageIndex}
                totalRecordCount={totalRecordCount}
                markedAllPaymentStatus={markedAllPaymentStatus}
              />  
            )}
          </TabArea>
        </SubmissionContainer>
    );
} 

const mapStateToProps = createStructuredSelector({
  token: selectUserJWTToken,
  productTypes: selectProductTypes,
  productGroups: selectProductGroups,
});

const mapDispatchToProps = (dispatch: any) => ({
  setConfigStartAction: () => dispatch(setConfigStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminCommissionsByArtist);
