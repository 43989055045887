import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useParams, useHistory } from "react-router-dom";
import { 
  SubmissionInterface, 
  SubmissionSearchFieldInterface,
  GetSubmissionsResponseInterface,
  ArtStatusInterface,
  ScheduleTagInterface,
  InternalSubmissionsInterface,
} from "./admin-art-submission-types";

import { setConfigStart } from "../../redux/settings/settings.action";

import { FormControlLabel, Checkbox } from "@material-ui/core";

import {
  selectUserAccount,
  selectUserJWTToken,
} from "../../redux/user/user.selector";

import { selectScheduleTags, selectStatusList } from "../../redux/settings/settings.selector";

import axios from "axios";
import Swal from "sweetalert2";

import Calendar from "./calendar.component";
import { AdminArtCardThumb as ArtCardThumb } from "../ArtCards";
import AutocompleteSearch from "../AutocompleteSearch";
import { BtnArtSubmit } from "../Button";

import { Loader } from "../Common";

import teefuryBirdLogo from "../../assets/teefury-bird.jpg";

import { MenuItem, Select } from "@material-ui/core";
import InfiniteScroll from 'react-infinite-scroller';
import { SITE_BACKEND_API_URL, SITE_BACKEND_FULL_URL } from "../../server";

import {
  SubmissionContainer,
  TabArea,
  FilterHeader,
  TabHeader,
  TabTitle,
  TabSubLink,
  TabSubTitle,
  ArtCardContainer,
} from "../SharedStyle/art-submissions.styles";
import { ARTS_PER_PAGE, MAX_ART_SELECTION_ALLOWED } from "../../constants";
import { dateToLocaleDateString } from "../../utils";
import { generateProducts, isSameCreationTypeSelected } from "../../utils/generateProducts";
import { AutomationAllowedInStatus } from "../../constants";
import { buttonAndTextFontStyle, filterDropdownStyle } from "./common-styles";
import { AdminArtAutomationForm } from "./admin-art-automation-form.component";
import { AdminArtSubmissionsSearch } from "./admin-art-submissions-search-component";
import AdminArtHeaderForm from "./admin-art-header-form.component";

const TopFilterTitle = (props: { title: String }) => {
  const { title } = props;
  return <div style={{fontWeight: "bold", fontSize: "18px", color: "#AAA", padding: "10px 0"}}>Sort By <span style={{color: "#000"}}>{title}</span></div>
}

const TopTitle = (props: { title: String }) => {
  const { title } = props;
  return <div style={{fontWeight: "bold", fontSize: "18px", color: "#AAA", padding: "10px 0"}}><span style={{color: "#000"}}>{title}</span></div>
}

const TealCheckbox = (props: any) => <Checkbox color="default" {...props} />;

interface AdminArtSubmissionsThumbInteface {
  userAccount: any;
  token: string;
  scheduleTags: ScheduleTagInterface[];
  artStatusList: ArtStatusInterface[];
  refreshSettings: Function;
}

type ArtistType = 'NEW' | 'APPROVED';

const AdminArtSubmissionsThumb = (props: AdminArtSubmissionsThumbInteface) => {
  const {
    userAccount: { contactEmail, userRole },
    token,
    scheduleTags,
    artStatusList,
    refreshSettings,
  } = props;

  type Params = { status: string };

  const params = useParams<Params>();
  const [state, setState] = useState({
    search: "",
    isShowingFilter: false,
    status: "NEW",
    imageSrc: teefuryBirdLogo,
    submissionsArrIndex: 0,
    id: 0,
    scheduleType: '',
    isAnArtCardSelected: false,
    isAdminArtApproval: false,
    isAdminArtDecline: false,
    isFlipLeftDisabled: false,
    isFlipRightDisabled: false,
    loading: true,
  });
  let [selectionCount, setSelectionCount] = useState(0);
  const [submissionsArr, setSubmissionsArr] = useState<SubmissionInterface[]>([]);
  const [internalSubmissionUpdates, setInternalSubmissionUpdates] = useState<InternalSubmissionsInterface>({});
  const [submissionsSearchData, setSubmissionsSearchData] = useState<SubmissionSearchFieldInterface[]>([]);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(new Date().setDate(new Date().getDate() - 4)),
    endDate: new Date(),
  });

  // const [showOnlyApprovedArtist, setShowOnlyApprovedArtist] = useState(false);
  const [showOnlyArtistTypes, setShowOnlyArtistTypes] = useState<ArtistType[]>([]);
  const [selectedSubmissionIds, setSelectedSubmissionIds] = useState<number[]>([]);
  const [isSelectedAllSubmission, setIsSelectedAllSubmission] = useState(false);
  const [allSubmissionIds, setAllSubmissionIds] = useState<number[]>([]);

  const [pageNo, setPageNo] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [hasMorePage, setHasMorePage] = useState(false);
  const [creationType, setCreationType] = useState<string | null>(null);
  const [scheduleDate, setScheduleDate] = useState(null);

  const { startDate, endDate } = dateRange;

  const {
    search,
    isShowingFilter,
    status,
    submissionsArrIndex,
    id,
    scheduleType,
    isAdminArtApproval,
    isAdminArtDecline,
    isFlipLeftDisabled,
    isFlipRightDisabled,
    loading,
  } = state;

  const history = useHistory();

  useEffect(
    () => {
      setSubmissionsArr([]);
      
      const status = _getCurrentPath();
      if (status === "DECLINED" || status === "DELETED") {
        _getSubmissionsByDate(startDate, endDate);
      } else {
        _getSubmissions(status);
      }
    },
    // eslint-disable-next-line
    [params]
  );

   const applyFilter = (event: any) => {
       
       const scheduleType = event.target.value;
       
        const status = _getCurrentPath();
        if (status === "DECLINED" || status === "DELETED") {
          _getSubmissionsByDate(startDate, endDate, scheduleType);
        } else {
          _getSubmissions(status, scheduleType);
        }
   };

  const changeStatusFilter = (event: any) => {
    const selectedStatus = event.target.value;
    history.push(selectedStatus);
  }
  
  const _getCurrentPath = () => {
    const status = params.status.toUpperCase();
    return status;
  };

  const _handleSelectArtCard = (id: number) => {

    // console.log('_handleSelectArtCard : '+id);

    if(selectedSubmissionIds.includes(id)) {
      const newSubmissionIds = selectedSubmissionIds.filter(item => item != id)

      // console.log(newSubmissionIds);
      setSelectedSubmissionIds(newSubmissionIds);

      // check if removed all
      if (newSubmissionIds.length == 0) {
        // Reset creation fields
        setCreationType(null);
        setScheduleDate(null);
      }
    } else {
      const newSubmissionIds = [ ...selectedSubmissionIds ];
      newSubmissionIds.push(id);

      if (newSubmissionIds.length > MAX_ART_SELECTION_ALLOWED) {
        
        Swal.fire({
          icon: "error",
          title: `You can select maximum ${MAX_ART_SELECTION_ALLOWED} arts to process at once!`,
        });

        return false;
      }

      // console.log(newSubmissionIds);
      setSelectedSubmissionIds(newSubmissionIds);
    }
  }

  const _handleSelectAllArt = () => {
    if (isSelectedAllSubmission) {
      setSelectedSubmissionIds([]);
      setSelectionCount(0);
    } else {

      if (!hasAnyFilterApplied() && allSubmissionIds.length > MAX_ART_SELECTION_ALLOWED) {
        
        Swal.fire({
          icon: "error",
          title: `You can select maximum ${MAX_ART_SELECTION_ALLOWED} arts to process at once!`,
        });

        return false;
      }

      // setSelectedSubmissionIds(submissionsArr.map((s) => s.id))
      // setSelectionCount(submissionsArr.length);

      setSelectedSubmissionIds(allSubmissionIds);
      setSelectionCount(allSubmissionIds.length);
    }

    setIsSelectedAllSubmission(!isSelectedAllSubmission);
  }

  const _getSubmissions = async (status: any, scheduleType = "", artistTypes: ArtistType[] = []) => {
    
    if(!scheduleType) {
        scheduleType = "";
    }
    
    console.log(scheduleType);
    
    setState({...state, loading: true, status, scheduleType});
    // const fetchOnlyApprovedArtist = onlyApprovedArtist ? 'YES' : 'NO';

    setSelectionCount(0);

    const {
      data: { submissionsDetailsArr, submissionsSearchArr, pageCount, totalCount: totalRecordsCount },
    } = await axios.get<GetSubmissionsResponseInterface>(`/api/admin/submissions/${status}?scheduleType=${scheduleType}&artistTypes=${showOnlyArtistTypes.join(',')}&search=${search}`, {
      headers: { Authorization: `JWT ${token}` },
    });

    setSelectedSubmissionIds([]);

    if(submissionsSearchArr) {
      // _formatDropdownArr(submissionsSearchArr);
      setSubmissionsSearchData(submissionsSearchArr);
      setAllSubmissionIds(submissionsSearchArr.map((s) => s.id))
    }

    setState({
      ...state,
      status,
      scheduleType,
      isShowingFilter: false,
      isAdminArtApproval: false,
      isAdminArtDecline: false,
      loading: false,
    });
    setSubmissionsArr(submissionsDetailsArr);

    if(pageCount > 1) {
      setPageNo(1);
      setHasMorePage(true);
    } else {
      setHasMorePage(false);
    }

    setTotalCount(totalRecordsCount);

    return submissionsDetailsArr;
  };
  
  const _getSubmissionsByDate = async (startDate: any, endDate: any, scheduleType = "", artistTypes: ArtistType[] = []) => {
      
    const start = dateToLocaleDateString(new Date(startDate));
    // .toLocaleDateString("en-CA", {
    //   timeZone: "GMT",
    // });
    const end = dateToLocaleDateString(new Date(endDate));
    // .toLocaleDateString("en-CA", {
    //   timeZone: "GMT",
    // });

    // const fetchOnlyApprovedArtist = onlyApprovedArtist ? 'YES' : 'NO';

    const status = _getCurrentPath();

    try {
        setState({...state, loading: true, status, scheduleType});
        setTotalCount(0);
      const {
        data: { submissionsDetailsArr, submissionsSearchArr, pageCount, totalCount: totalRecordsCount },
      } = await axios.post<GetSubmissionsResponseInterface>(
        `/api/admin/submissions/by-date`,
        {
          status,
          start,
          end,
          scheduleType,
          artistTypes,
        },
        {
          headers: { Authorization: `JWT ${token}` },
        }
      );

      if(submissionsSearchArr) {
        // _formatDropdownArr(submissionsSearchArr);
        setSubmissionsSearchData(submissionsSearchArr);
      }

      setState({
        ...state,
        scheduleType,
        status,
        loading: false,
        isShowingFilter: false,
        isAdminArtApproval: false,
      });
      setSubmissionsArr(submissionsDetailsArr);
      setPageNo(1);

      if(pageCount > 1) {
        setHasMorePage(true);
      } else {
        setHasMorePage(false);
      }

      setTotalCount(totalRecordsCount);

      return submissionsDetailsArr;
    } catch (error) {
        setState({...state, loading: false});
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Something went wrong. Please try again.",
      });
    }
  };
  
  const openAdminArtDecline = (event: any) => {
      setState({
      ...state,
      isAdminArtDecline: true,
    });
  } 
  
  const closeAdminArtDecline = (event: any) => {
      setState({
      ...state,
      isAdminArtDecline: false,
    });
  } 

  const handleAutocompleteChange = (event: any, { newValue, method }: any) => {
    setState({
      ...state,
      search: newValue,
    });
  };

  const handleAutocompleteSearch = async (event: any) => {
    event.preventDefault();

    try {
      let submissionsArr;
      if (status === "DECLINED" || status === "DELETED") {
        submissionsArr = await _getSubmissionsByDate(
          startDate,
          endDate
        );
      } else {
        submissionsArr = await _getSubmissions(status);
      }
    } catch (error) {
      Swal.fire("Something went wrong. Please try again.");
    }
  };

  const toggleFilterArea = () => {
    const { isShowingFilter } = state;
    setState({ ...state, isShowingFilter: !isShowingFilter });
  };

  const flipLeft = () => {
    if (submissionsArrIndex > 0) {
      let previousIndex = submissionsArrIndex - 1;
      const newCardId = submissionsArr[previousIndex].id;
      let isFlipLeftDisabled = previousIndex === 0 ? true : false;

      setState({
        ...state,
        id: newCardId,
        submissionsArrIndex: previousIndex,
        isFlipLeftDisabled,
        isFlipRightDisabled: false,
      });
    }
  };

  const flipRight = () => {
    if (submissionsArrIndex < submissionsArr.length - 1) {
      let nextIndex = submissionsArrIndex + 1;
      const newCardId = submissionsArr[nextIndex].id;
      const isFlipRightDisabled =
        nextIndex === submissionsArr.length - 1 ? true : false;

      setState({
        ...state,
        id: newCardId,
        submissionsArrIndex: nextIndex,
        isFlipLeftDisabled: false,
        isFlipRightDisabled,
      });
    }
  };

  const handleDateFilter = async ({ startDate, endDate } : any) => {
    try {
      await _getSubmissionsByDate(startDate, endDate);
      // setDateRange({ // commented to resolve "String" type date issue
      //   startDate,
      //   endDate,
      // });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Something went wrong. Please try again.",
      });
    }

    return;
  };

  const hasAnyFilterApplied = () => {
    return showOnlyArtistTypes.length > 0 || scheduleType || search;
  };

  const loadNextPage = async () => {

    if(pageNo == 0 || loading) {
      return true;
    }

    console.log("loadNextPage: "+pageNo);

    // const fetchOnlyApprovedArtist = showOnlyApprovedArtist ? 'YES' : 'NO';

    if (status === "DECLINED" || status === "DELETED")  {
      const start = dateToLocaleDateString(new Date(startDate))
      // .toLocaleDateString("en-CA", { timeZone: "GMT", });
      const end = dateToLocaleDateString(new Date(endDate))
      // .toLocaleDateString("en-CA", { timeZone: "GMT", });
      const status = _getCurrentPath();
  
      try {
        const {
          data: { submissionsDetailsArr, pageCount },
        } = await axios.post<GetSubmissionsResponseInterface>(
          `/api/admin/submissions/by-date`,
          {
            status,
            start,
            end,
            scheduleType,
            pageIndex: pageNo,
            showOnlyArtistTypes,
          },
          {
            headers: { Authorization: `JWT ${token}` },
          }
        );

        // Merge next page records
        setSubmissionsArr(submissionsArr.concat(submissionsDetailsArr));
        console.log("pageCount: "+pageCount);

        if(submissionsDetailsArr.length < ARTS_PER_PAGE) {
          setHasMorePage(false);
        }

      } catch (error) {
        console.log(error);
      }

    } else {
      const {
        data: { submissionsDetailsArr, pageCount },
      } = await axios.get<GetSubmissionsResponseInterface>(`/api/admin/submissions/${status}?scheduleType=${scheduleType}&search=${search}&pageIndex=${pageNo}&artistTypes=${showOnlyArtistTypes.join(',')}`, {
        headers: { Authorization: `JWT ${token}` },
      });

      // Merge next page records
      setSubmissionsArr(submissionsArr.concat(submissionsDetailsArr));

      console.log("pageCount: "+pageCount);

      if(submissionsDetailsArr.length < ARTS_PER_PAGE) {
        setHasMorePage(false);
      }
    }

    const nextPageNo = pageNo + 1;
    setPageNo(nextPageNo);

  }

  const onInternalChange = (submissionId: number, name: string, value: string) => {

    const updateKey = 'updated_'+submissionId.toString();

    if(!internalSubmissionUpdates[updateKey]) {
      // Set initial value
      const { internalTitle, internalMainInspiration, internalTags } =  submissionsArr.filter(s => s.id == submissionId)[0];
      internalSubmissionUpdates[updateKey] = { submissionId, internalTitle, internalMainInspiration, internalTags };
    }

    switch(name) {
      case 'internalTitle' :           
      case 'internalMainInspiration' : 
      case 'internalTags' :   
        internalSubmissionUpdates[updateKey][name] = value;           
        break;         
    }

    // console.log(internalSubmissionUpdates);

    setInternalSubmissionUpdates(internalSubmissionUpdates);

    const newSubmissionsArr = submissionsArr.map(s => { 
      if(s.id == submissionId) {
        s.internalTitle = internalSubmissionUpdates[updateKey].internalTitle;
        s.internalMainInspiration = internalSubmissionUpdates[updateKey].internalMainInspiration;
        s.internalTags = internalSubmissionUpdates[updateKey].internalTags;
      }
      return s;
    });
    
    setSubmissionsArr(newSubmissionsArr);

  }

  const saveChanges = async () => {
    console.log(internalSubmissionUpdates);

    try {
       await axios.put(`/api/admin/submissions/update-internal`, { 
        updates : { ...internalSubmissionUpdates },
      }, {
          headers: { Authorization: `JWT ${token}` },
        }
      );

      Swal.fire({
        icon: "success",
        title: "Arts updated successfully.",
      });

      // This is only required to update new suggested tag in store if added
      // refreshSettings();

    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Something went wrong. Please try again.",
      });
    }
  }

  /*
  const exportSubmission = async () => {

    const { status, scheduleType } = state;

    const {
        data: { csvPath },
    } = await axios({
        url: SITE_BACKEND_API_URL+"/admin/commissions/exportCSVSubmissions",
        method: "POST",
        data: { selectedSubmissionIds, isSelectedAllSubmission, status, scheduleType },
        headers: { Authorization: `JWT ${token}` },
    });

    console.log(csvPath);

    window.open(SITE_BACKEND_FULL_URL+"/"+csvPath, '_blank');
  }

  const saveAutomation = async () => {

    if(!creationType) {
      Swal.fire({
        icon: "error",
        title: "Creation type can't be empty!",
      });

      return false;
    }

    // Validate Creation type
    if (['ODAD', 'WEEKLY'].includes(creationType) && !scheduleDate) {
      Swal.fire({
        icon: "error",
        title: "Schedule Date can't be empty for ODAD and Weekly creation type!",
      });

      return false;
    }

    try {
      await axios({
        url: `/api/admin/submissions/save-automation`,
        method: "POST",
        data: {
          selectedSubmissionIds,
          creationType,
          scheduleDate: scheduleDate ? dateToLocaleDateString(scheduleDate) : null},
        headers: { Authorization: `JWT ${token}` },
      });

      Swal.fire({
        icon: "success",
        title: "Arts updated successfully.",
      });

    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Something went wrong. Please try again.",
      });
    }
  }
  */
  const applyFlaggedArtist = (artistType: ArtistType) => {
    
    // setShowOnlyApprovedArtist(isFlagged);
    
    let newTypes = showOnlyArtistTypes;

    if (newTypes.includes(artistType)) {
      var index = newTypes.indexOf(artistType);
      if (index !== -1) {
        newTypes.splice(index, 1);
      }
    } else {
      newTypes.push(artistType);
    }

    setShowOnlyArtistTypes(newTypes);

    const status = _getCurrentPath();
    if (status === "DECLINED" || status === "DELETED") {
      _getSubmissionsByDate(startDate, endDate, scheduleType, newTypes);
    } else {
      _getSubmissions(status, scheduleType, newTypes);
    }
  };

  const generateSubmission = async () => {

    setState({...state, loading: true });
    const isSuccess = await generateProducts(submissionsArr, selectedSubmissionIds, token);
    setState({...state, loading: false });

    if (isSuccess) {
      setTimeout(() => {
        _getSubmissions(status);
      }, 1000);
    }
  }

  const onEmailTemplateSelector = () => {
    // setState({
    //   ...state,
    //   isAdminArtDecline: true,
    // });

    // setSelectedEmailTemplate(selectedEmailTemplate)
    // setSubmissionUpdate(submissionUpdate);
  }

  const createImages = async () => {

    const creationType = isSameCreationTypeSelected(submissionsArr, selectedSubmissionIds)

    if(creationType === false) {
      return false;
    }

    setState({...state, loading: true });

    const {
        data: filePaths,
    } = await axios({
        url: SITE_BACKEND_API_URL+"/admin/odad/exportAllArts",
        method: "POST",
        data: { showOnlyArtistTypes, scheduleType, selectedSubmissionIds },
        headers: { Authorization: `JWT ${token}` },
    });

    // Push to print server
    await axios({
      url: SITE_BACKEND_API_URL+"/admin/odad/pushArtsToPrintServer",
      method: "POST",
      data: { ...filePaths, sendToImageProgram: 'YES', creationType },
      headers: { Authorization: `JWT ${token}` },
    });

    setState({...state, loading: false });
    
    Swal.fire({
      title: "The art files sent to Image Creation successfully!",
      icon: "success",
    });

    setSelectedSubmissionIds([]);
    setSelectionCount(0);
    setIsSelectedAllSubmission(false);
    
  }

  return (
    <SubmissionContainer>
      <TabHeader>
        <TabSubLink to={`/admin/art-submissions/grid/${status}`}>
          <TabSubTitle>Grid View</TabSubTitle>
        </TabSubLink>
        <TabTitle>Thumbnail View</TabTitle>
      </TabHeader>
      <TabArea style={{position: "relative"}}>

        <div style={{position: "absolute", right: "60px"}}>
          <AdminArtSubmissionsSearch
            search={search}
            handleAutocompleteChange={handleAutocompleteChange}
            handleAutocompleteSearch={handleAutocompleteSearch}
            submissions={submissionsSearchData}
          />
        </div>

        <div style={{position: "absolute", left: "60px", top: "20px"}}>
          <FormControlLabel
            control={
              <Checkbox color="default"
                checked={showOnlyArtistTypes.includes('APPROVED')}
                onChange={() => { applyFlaggedArtist('APPROVED') }}
              />
            }
            label="Show all submissions by an approved artist"
            style={{ }}
          />
        </div>
        {/* This is still in staging yet */}
        {/* <div style={{position: "absolute", left: "60px", top: "50px"}} >
          <FormControlLabel
            control={
              <Checkbox color="default"
                checked={showOnlyArtistTypes.includes('NEW')}
                onChange={() => { applyFlaggedArtist('NEW') }}
              />
            }
            label="Show all submissions by an new artist"
            style={{ }}
          />
        </div> */}

        <h2 style={{ textAlign: "center", margin: 0 }}>{status.replaceAll('-', ' ')} ({totalCount})</h2>

        {!(selectedSubmissionIds.length > 0) ?
        ( <FilterHeader>
          <div style={{display: "flex", margin: "30px 0", alignItems: "end"}}>
            <div>
              <TopFilterTitle title="Status"/>
              <Select
                  value={status}
                  onChange={changeStatusFilter}
                  disableUnderline
                  displayEmpty
                  style={{...buttonAndTextFontStyle, ...filterDropdownStyle}}
                >
                  {artStatusList.map((artStatus) => (
                      <MenuItem key={artStatus.id} value={artStatus.status_name.replaceAll(' ', '-')} style={buttonAndTextFontStyle}>{artStatus.status_name}</MenuItem>
                  ))}
                
              </Select>
            </div>
            <div style={{marginLeft: "40px"}}>
              <TopFilterTitle title="Schedule Type"/>
              <Select
                id="scheduleTypeSelect"
                name="scheduleType"
                  value={scheduleType}
                  onChange={applyFilter}
                  disableUnderline
                  displayEmpty
                  style={{...buttonAndTextFontStyle, ...filterDropdownStyle}}
                >
                  <MenuItem key="all" value="" style={buttonAndTextFontStyle}>All Type</MenuItem>

                  {scheduleTags.map((scheduleTag) => (
                      <MenuItem key={scheduleTag.id} value={scheduleTag.tag_name} style={buttonAndTextFontStyle}>{scheduleTag.tag_name}</MenuItem>
                  ))}
                
              </Select>
            </div>

            {submissionsArr.length > 0 && 
              <BtnArtSubmit
                type="submit"
                textAlign="right"
                style={{ backgroundColor: "#0B7C80", cursor: "pointer", marginLeft: "20px" }}
                onClick={saveChanges}
              >
                Save Changes
              </BtnArtSubmit>
            }
          </div>
        </FilterHeader>
        ) : (
          <>
          <AdminArtHeaderForm 
            selectedSubmissionIds={selectedSubmissionIds}
            submissionsArr={submissionsArr}
            onEmailTemplateSelector={onEmailTemplateSelector}
            isSelectedAllSubmission={isSelectedAllSubmission}
            onImageGeneration={createImages}
          />
          
          <AdminArtAutomationForm 
            selectedSubmissionIds={selectedSubmissionIds}
            token={token}
            onUpdate={() => _getSubmissions(status)}
            isSelectedAllSubmission={isSelectedAllSubmission}
          />
          </>
        )}
        
        {(status === "DECLINED" || status === "DELETED") &&
          <Calendar
            handleDateFilter={handleDateFilter}
            globalStartDate={startDate}
            globalEndDate={endDate}
          />
        }
        {submissionsArr.length > 1 &&
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <FormControlLabel
              control={
                <TealCheckbox
                  checked={isSelectedAllSubmission}
                  onChange={_handleSelectAllArt}
                />
              }
              label="Select All"
              style={{ }}
            />    
            { selectionCount > 0 && <div>Total Selected: { selectionCount }</div> }
          </div>
        }
        <InfiniteScroll
            pageStart={0}
            loadMore={loadNextPage}
            hasMore={submissionsArr.length > 0 && hasMorePage}
            loader={loading ? <></> : <Loader />}
        >
            <ArtCardContainer key={pageNo} items={submissionsArr.length} style={{display: "block"}}>
            {loading ? ( <Loader /> ) : (
              submissionsArr.length > 0 ? (
                  <>
                  {submissionsArr.map((submissionDetails, i) => (
                    <ArtCardThumb
                      key={i}
                      submission={submissionDetails}
                      token={token}
                      index={i}
                      onInternalChange={onInternalChange}
                      isSelected={selectedSubmissionIds.includes(submissionDetails.id)}
                      handleSelectArtCard={_handleSelectArtCard}
                    />
                  ))}
                </>
              ) : (
                <h2 style={{textAlign: "center"}}>No Artwork to be Viewed</h2>
              ))}
            </ArtCardContainer>
        </InfiniteScroll>
      </TabArea>
    </SubmissionContainer>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  refreshSettings: () => dispatch(setConfigStart()),
});

const mapStateToProps = createStructuredSelector({
  userAccount: selectUserAccount,
  token: selectUserJWTToken,
  scheduleTags: selectScheduleTags,
  artStatusList: selectStatusList,
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminArtSubmissionsThumb);
