import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import storageSession from 'redux-persist/lib/storage/session';
import userReducer from "./user/user.reducer";
import artistReducer from "./artist/artist.reducer";
import submissionsReducer from "./submissions/submissions.reducer";
import settingsReducer from "./settings/settings.reducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "artist", "submissions"],
};

// This is to session storage
const settingPersistConfig = { 
  key: 'settings', 
  storage: storageSession
};

const rootReducer = combineReducers({
  user: userReducer,
  artist: artistReducer,
  submissions: submissionsReducer,
  settings: persistReducer(settingPersistConfig, settingsReducer),
});

export default persistReducer(persistConfig, rootReducer);
