import React, { useState, useEffect, MouseEventHandler, ChangeEvent } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Swal from "sweetalert2";
import { ApprovedFlag, NewArtistFlag, Loader } from "../Common";
import SuggestedTags from "../Common/suggested-tags.component";
import DisplayTags from "../Common/display-tags.component";
import { Tag } from 'react-tag-input';
import { setConfigStart } from "../../redux/settings/settings.action";
import { ArtworkType, ProductCreationTypes, UserRole } from "../../constants";
import { ODAD_AUTOMATION_API_URL } from "../../server";
import {
  selectUserAccount,
  selectUserJWTToken,
} from "../../redux/user/user.selector";

import { selectScheduleTags, selectStatusList, selectColors } from "../../redux/settings/settings.selector";

import teefuryBirdLogo from "../../assets/teefury-bird.jpg";
import { ReactComponent as UploadIcon } from "../../assets/upload.svg";
import { ReactComponent as LoadingIcon } from "../../assets/loading.svg";
import { MainButton } from "../Button";
import EmailTemplate from "./email-template.component";
import LogHistory from "./log-history.component";

import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import DeleteIcon from "@material-ui/icons/Delete";
import DoneIcon from "@material-ui/icons/Done";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import SaveIcon from "@material-ui/icons/Save";
import { IconButton, MenuItem, Select, Popover } from "@material-ui/core";
import ColorCheckbox from "../FormInput/color-checkbox.component";
import { DatePicker } from "../FormInput/date-picker.component";
import CustomAutocomplete from "../Common/custom-autocomplete.component";
import CustomPopover from "../Common/custom-popover.component";
import { capitalizeSentence } from "../../utils";
import { PRIMARY_TAG_INFO, OTHER_TAG_INFO } from "../../constants/text";

import {
  TabArea,
  FilterHeader,
  AdjustableIconWrapper,
  ArtworkContainer,
  PreviewImage,
  ArtPreview,
  ArtFileButtonsWrapper,
  IconContainer,
  IconBottomSubtitle,
  SubmitCard,
  FormInputTitleStyled,
  TextAreaStyled,
  GreyTextArea,
  GreyTextAreaZeroMargin,
  CaptionTitle,
  EmailStatus,
  DownloadLink,
  CenterButtonsWrapper,
  FormInputTitleEditable,
  TextAreaStyledEditable,
} from "./admin-art-approval.styles";

import { 
  SubmissionInterface, 
  GetOneSubmissionResponseInterface,
  DropdownElementInterface,
  LogInterface,
  ArtStatusInterface,
  ScheduleTagInterface,
  ColorsListInterface,
  CreationTypeEnum,
} from "./../AdminArtSubmissions/admin-art-submission-types";

import { ArtCanvas } from "./art-canvas";
import { dateToLocaleDateString } from "../../utils";

const buttonAndTextFontStyle = {
  fontFamily:
    "'-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  fontSize: "15px",
  fontWeight: "bold"
} as React.CSSProperties;

const cntrTxtBtnsWithIcons = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
} as React.CSSProperties;

const deleteArtFileBtnStyle = {
  fontSize: "10px",
  fontWeight: "normal",
  backgroundColor: "transparent",
  border: "1px solid #c23b22",
  color: "#c23b22",
  width: "150px",
  marginRight: "10px",
} as React.CSSProperties;

const nextPreviousBtnStyle = {
  fontFamily:
    "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  fontSize: "14px",
} as React.CSSProperties;

interface AdminArtApprovalInterface {
  id: number;
  userAccount: any;
  token: string;
  closeAdminArtApproval: Function;
  flipLeft: null|MouseEventHandler<HTMLAnchorElement>;
  flipRight: null|MouseEventHandler<HTMLAnchorElement>;
  isFlipLeftDisabled: boolean;
  isFlipRightDisabled: boolean;
  scheduleTags: ScheduleTagInterface[];
  artStatusList: ArtStatusInterface[];
  colorsList: ColorsListInterface[];
  additionalColorsList: ColorsListInterface[];
  refreshSettings: Function;
}

interface InternalSubmissionInterface {
  id: number;
  internalTitle: string;
  internalDescription: string;
  internalMainInspiration: string;
  internalPrimaryTag: string;
  internalTags: string;
  internalMainColor: number;
  internalAdditionalColors: number[];
  scheduleType: string;
  creationType: CreationTypeEnum;
  scheduleDate: string;
  odadPosition: 'D1'|'D2';
  status: string;
}

const AdminArtApproval = (props: AdminArtApprovalInterface) => {
  const {
    id,
    userAccount: { contactEmail, userRole },
    token,
    closeAdminArtApproval,
    flipLeft,
    flipRight,
    isFlipLeftDisabled,
    isFlipRightDisabled,
    scheduleTags,
    artStatusList,
    colorsList,
    additionalColorsList,
    refreshSettings,
  } = props;

  const [submission, setSubmission] = useState<SubmissionInterface>();
  const [internalSubmissionUpdates, setInternalSubmissionUpdates] = useState<InternalSubmissionInterface>();
  const [previousDetails, setPreviousDetails] = useState({
    previousTitle: "",
    previousDescription: "",
    previousStatus: "",
    previousEmailStatus: "",
    previousEmailContent: "",
  });
  
  const [isDisableSubmit, setIsDisableSubmit] = useState(false);
  const [isEnlargeImg, setIsEnlargeImg] = useState(false);
  const [isArtFileDeleted, setIsArtFileDeleted] = useState(false);
  const [artPreviewImg, setArtPreviewImg] = useState("");
  // const [artFileDownload, setArtFileDownload] = useState("");
  const [emailStatus, setEmailStatus] = useState("Not emailed");
  const [canvasBackColorCode, setCanvasBackColorCode] = useState("");
  const [emailStatusColor, setEmailStatusColor] = useState({
    color: "#6a6a6a",
  });

  const [duplicateDetectionTimer, setDuplicateDetectionTimer] = useState<NodeJS.Timeout | null>(null);
  const [isTitleExist, setIsTitleExist] = useState(false);
  const [checkingForDuplicate, setCheckingForDuplicate] = useState(false);

  const {
    previousTitle,
    previousDescription,
    previousStatus,
    previousEmailStatus,
    previousEmailContent,
  } = previousDetails;

  useEffect(
    () => {
      _loadArtwork();
      
      // console.log('Refreshing settings...');
      // refreshSettings();
    },
    //eslint-disable-next-line
    [id]
  );

  const handleScheduleDateChange = (date: any) => {
    if(internalSubmissionUpdates) {
      const newSubmission = { ...internalSubmissionUpdates };
      newSubmission.scheduleDate = dateToLocaleDateString(date);
      setInternalSubmissionUpdates({ ...newSubmission });

      console.log(newSubmission);
    }
  }

  const handleChange = (event: any) => {

    type InputData = { 
      name: keyof InternalSubmissionInterface, 
      value: string 
    };

    const { name, value } = event.target as InputData;

    if(internalSubmissionUpdates) {

      const newSubmission = { ...internalSubmissionUpdates };

      switch(name) {
        case 'internalTitle':
        case 'internalDescription':
        case 'internalTags':
        case 'internalMainInspiration':
        case 'scheduleType':
        case 'status':
          newSubmission[name] = value;
          break;
        case 'creationType':
        case 'odadPosition':
          // @ts-ignore
          newSubmission[name] = event.target.value;
          break;
      }
      setInternalSubmissionUpdates({ ...newSubmission });
    }

    if (name == 'internalTitle') {
      setCheckingForDuplicate(true);
      // Check for duplicate

      if (duplicateDetectionTimer) {
        clearTimeout(duplicateDetectionTimer);
      }
  
      const timer = setTimeout(async () => {
        // Check if title is already taken
        const {
          data
        } = await axios.get("/api/artist/is-art-title-exist?title="+encodeURIComponent(value.trim()), {
          headers: {
            Authorization: `JWT ${token}`,
          },
        });
  
        if (data.status === 'TITLE_EXIST') {
          setIsTitleExist(true);
        } else {
          setIsTitleExist(false);
        }

        setCheckingForDuplicate(false);
  
      }, 2000);
  
      setDuplicateDetectionTimer(timer);
    }

    // setIsDisableSubmit(false);
  };

  const handleSave = async () => {

    if(!internalSubmissionUpdates || !submission) {
      return;
    }

    if (isTitleExist) {
      Swal.fire({
        icon: "error",
        text:
          "Title already taken.",
        showConfirmButton: true,
      });
      return;
    }

    const timeOptions = {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    } as Intl.DateTimeFormatOptions;

    let logs = await _getLogs();

    if (logs !== null) {
      logs = JSON.parse(logs);
    } else {
      logs = [];
    }

    if (previousTitle !== internalSubmissionUpdates.internalTitle) {
      logs.push({
        logInfo: `${contactEmail} changed title from ${previousTitle} to ${internalSubmissionUpdates.internalTitle}`,
        logTimestamp: new Date().toLocaleTimeString("en-US", timeOptions),
      });
    }
    if (previousStatus !== internalSubmissionUpdates.status) {
      logs.push({
        logInfo: `${contactEmail} changed status from ${previousStatus} to ${internalSubmissionUpdates.status}`,
        logTimestamp: new Date().toLocaleTimeString("en-US", timeOptions),
      });
    }

    logs = JSON.stringify(logs);

    try {

      const { creationType, scheduleDate, odadPosition } = internalSubmissionUpdates;

      // Validate Creation type
      if (['ODAD', 'WEEKLY'].includes(creationType) && !scheduleDate) {
        Swal.fire({
          icon: "error",
          title: "Schedule Date can't be empty for ODAD and Weekly creation type!",
        });

        return false;
      }
      
      if (creationType === 'ODAD' && !odadPosition) {
        Swal.fire({
          icon: "error",
          title: "Please select ODAD Position!",
        });
        return false;
      }

      const { internalMainColor, internalAdditionalColors } = submission;

      await axios.put(
        `/api/admin/submissions`,
        { ...internalSubmissionUpdates, internalMainColor, internalAdditionalColors, logs },
        {
          headers: { Authorization: `JWT ${token}` },
        }
      );

      if (internalSubmissionUpdates.status === "DECLINED") {
        _deleteDeclinedArtFileById();
      }

      // This is only required to update new suggested tag in store if added
      // console.log('Refreshing settings...');
      // refreshSettings();

      Swal.fire({
        icon: "success",
        title: "Successfully saved your changes!",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Something went wrong. Please try again.",
      });
    }

    setPreviousDetails({
      ...previousDetails,
      previousTitle: internalSubmissionUpdates.internalTitle,
      // previousDescription: description,
      previousStatus: internalSubmissionUpdates.status,
    });
  };

  const changeEmailStatusColor = async (approvalType: string) => {
    if (approvalType !== "Not emailed") {
      try {
        const {
          data: {
            templateColor: { templateColor },
          },
        } = await axios.get(
          `/api/admin/settings/email-templates/get-template-color/${approvalType.replace(/\s+/g, '')}`,
          {
            headers: { Authorization: `JWT ${token}` },
          }
        );

        if (templateColor !== "") {
          setEmailStatusColor({ color: templateColor });
        }

        setEmailStatus(approvalType);
      } catch (error) {
        console.log(error);
        Swal.fire(
          "Something went wrong while getting email template colors. Please try again."
        );
      }
    }
  };

  const clickEnlargeImg = () => {
    setIsEnlargeImg(!isEnlargeImg);
  };
  
    const getArtworkName = () => {
      if(!submission) {
        return "None";
      }

      const { artworkType } = submission;
      
      if(artworkType === ArtworkType.classic) {
          return "Classic Artwork";
      } else if(artworkType === ArtworkType.allOver) {
          return "All Over/Patterned Artwork";
      } else {
          return "None";
      }
  };

  const _loadArtwork = async () => {
    try {
      const submissionDetails = await _getSubmittedArtwork();
      
      if(submissionDetails.artworkType == ArtworkType.allOver) {
        _loadPreviewArt(submissionDetails.previewArt);
      }
      // _loadArtFile(artFile);

      submissionDetails.artFile === "" || submissionDetails.artFile === null
        ? setIsArtFileDeleted(true)
        : setIsArtFileDeleted(false);
      changeEmailStatusColor(emailStatus);

      setSubmission(submissionDetails);

      console.log(submissionDetails);

      const internalSubmissionDetails = {
        id: submissionDetails.id,
        internalTitle: submissionDetails.internalTitle,
        internalDescription: submissionDetails.internalDescription,
        internalMainInspiration: submissionDetails.internalMainInspiration,
        internalPrimaryTag: submissionDetails.internalPrimaryTag,
        internalTags: submissionDetails.internalTags,
        scheduleType: submissionDetails.scheduleType,
        creationType: submissionDetails.creationType,
        scheduleDate: submissionDetails.scheduleDate,
        odadPosition: submissionDetails.odadPosition,
        status : submissionDetails.status
      } as InternalSubmissionInterface;

      setInternalSubmissionUpdates(internalSubmissionDetails);

      setEmailStatus(submissionDetails.emailStatus);

      setPreviousDetails({
        previousTitle: submissionDetails.internalTitle,
        previousDescription: submissionDetails.internalDescription,
        previousStatus: submissionDetails.status,
        previousEmailStatus: submissionDetails.emailStatus,
        previousEmailContent: '',
      });
      // setArtFileDownload(submissionDetails.artFile);

      if(submissionDetails.artworkType == ArtworkType.classic) {
        const mainColorData = colorsList.filter(color => color.id == submissionDetails?.internalMainColor);
        setCanvasBackColorCode("#"+mainColorData[0].color_code);
      }

    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        text: "Sorry something went wrong, Please check back later.",
        showConfirmButton: false,
      });
    }
  };

  // eslint-disable-next-line
  /*
  const _loadArtFile = async (artFile: string) => {
    try {
      const artFileDownload = await _createBlob(artFile);
      setArtFileDownload(artFileDownload);
    } catch (error) {
      Swal.fire({
        icon: "error",
        text: "Sorry could not load PSD file",
        showConfirmButton: false,
      });
    }
  };
  */
  
  const _loadPreviewArt = async (previewArt: string) => {
    if (!previewArt) {
      setArtPreviewImg(teefuryBirdLogo);
    } else {
      try {
        const largeThumb = `/api/art-submissions-thumb/?src=${previewArt.substring(
          20
        )}&w=500`;
        const artPreviewImg = await _createBlob(largeThumb);
        setArtPreviewImg(artPreviewImg);
      } catch (error) {
        Swal.fire({
          icon: "error",
          text: "Sorry could not load art preview file",
          showConfirmButton: false,
        });
      }
    }
  };

  const _getSubmittedArtwork = async () => {
    const {
      data: { submissionDetails }, // To fix cache issue made "review2"
    } = await axios.get<GetOneSubmissionResponseInterface>(`/api/admin/submissions/review/${id}`, { 
      headers: {
        Authorization: `JWT ${token}`,
      },
    });

    return submissionDetails;
  };
  
  // const _getScheduleTags = async () => {
  //   const {
  //     data: { scheduleTagsArr }, // To fix cache issue made "review2"
  //   } = await axios.get(SITE_BACKEND_API_URL+"/admin/scheduletags/list", { 
  //     headers: {
  //       Authorization: `JWT ${token}`,
  //     },
  //   });

  //   return scheduleTagsArr;
  // };
  
  // const _getArtStatusList = async () => {
  //   const {
  //     data: artStatusList,
  //   } = await axios.get(SITE_BACKEND_API_URL+"/common/api/getartstatuslist", { 
  //     headers: {
  //       Authorization: `JWT ${token}`,
  //     },
  //   });

  //   return artStatusList;
  // };

  const _createBlob = async (previewArt: string) => {
    return await fetch(previewArt, {
      headers: { Authorization: `JWT ${token}` },
    })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        return URL.createObjectURL(blob);
      });
  };

  const _deleteDeclinedArtFileById = () => {
    axios.delete(`/api/admin/submissions/declined-art-file/${id}`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
    });
    setIsArtFileDeleted(true);
  };

  const _deleteArtFileById = () => {
    axios.delete(`/api/admin/submissions/status-to-deleted/${id}`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
    });
    setIsArtFileDeleted(true);
  };

  const _handleDeleteArtFileClick = () => {
    Swal.fire({
      title: "",
      text: "Are you sure you want to delete this submission?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonColor: "#d33",
    }).then((response) => {
      if (response.value) {
        Swal.fire({
          title: "The submission has been deleted!",
          icon: "success",
        });

        _deleteArtFileById();
        closeAdminArtApproval();
      }
    });
  };

  const _getLogs = async () => {
    try {
      const { data } = await axios.get(`/api/admin/submissions/logs/${id}`, {
        headers: {
          Authorization: `JWT ${token}`,
        },
      });

      return data;
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Something went wrong. Please try again.",
      });
    }
  };

  const _handleMainColorChange = (colorId: number) => {
    if(!submission) {
      return true;
    }

    // Check if deselect
    if(submission.internalMainColor == colorId) {
      setSubmission({ ...submission, internalMainColor: 0 })
      setCanvasBackColorCode("");
    } else {
    
      const internalAdditionalColors = submission.internalAdditionalColors.filter(color => color !== colorId);
      const internalMainColor = colorId;

      setSubmission({ ...submission, internalMainColor, internalAdditionalColors })

      const mainColorData = colorsList.filter(color => color.id == internalMainColor);
      setCanvasBackColorCode("#"+mainColorData[0].color_code);
    }
  };

  const handleMainColorMouseOver = (colorHash: string, type: 'IN'|'OUT') => {
    if(type == 'IN') {
      setCanvasBackColorCode(colorHash);
    } else {
      const mainColorData = colorsList.filter(color => color.id == submission?.internalMainColor);
      setCanvasBackColorCode(mainColorData.length ? "#"+mainColorData[0].color_code : '');
    }
  }
  
  const _handleAdditionalColorChange = (colorId: number) => {
    if(!submission) {
      return true;
    }

    const updatedSubmission  = { ...submission };

    const { internalAdditionalColors, internalMainColor } = updatedSubmission;
  
    if(internalAdditionalColors.includes(colorId)) {
      // Remove
      const newColors = internalAdditionalColors.filter(c => c != colorId);
      updatedSubmission.internalAdditionalColors = newColors;
      setSubmission(updatedSubmission);
    } else {
      // Allowed to select only 4 colors
      if(internalAdditionalColors.length == 4) {
        Swal.fire({
          icon: "error",
          text:
            "Sorry you can select maximum 4 colours only.",
          showConfirmButton: true,
        });
      } else {
        // include
        if(internalMainColor == colorId) {
          Swal.fire({
            icon: "error",
            text:
              "Main color should not be part of additional color.",
            showConfirmButton: true,
          });
        } else {
          updatedSubmission.internalAdditionalColors.push(colorId);
          setSubmission(updatedSubmission);
        }
      }
    }
  };

  const _getExternalStatusName = (status: string) => {
    const selectedStatus = artStatusList.filter(s => s.status_name == status);
    return selectedStatus[0].external_status_name;
  }

  const _getColorInfo = (color: any) => {
    return {
      colorId: color.id, 
      colorCode: color.color_code, 
      colorName: color.color_name,
      isLightColor: color.is_light_color
    }
  }

  const onFlipLeft = (e: any) => {
    setSubmission(undefined);
    setInternalSubmissionUpdates(undefined);
    if (flipLeft) {
      flipLeft(e);
    }
  }

  const onFlipRight = (e: any) => {
    setSubmission(undefined);
    setInternalSubmissionUpdates(undefined);
    if (flipRight) {
      flipRight(e);
    }
  }

  const doNothing = () => {};

  const handlePrimaryTagChange = (tag: string) => {
    if(internalSubmissionUpdates) {
      setInternalSubmissionUpdates({ ...internalSubmissionUpdates, internalPrimaryTag: tag });
    }

    // Fetch associated tags
    onPrimaryTagSelected(null, { suggestionValue: tag })
  }

  const onPrimaryTagSelected = (event: any, data: any) => {

    // Fetch associated tags
    fetch(ODAD_AUTOMATION_API_URL+'/getAssociatedTags?primary_tag='+data.suggestionValue)
    .then(res => res.json())
    .then(res => {
      
      // Set as other tags
      if(internalSubmissionUpdates) {

        const associatedTags = res.tags;

        // Merge both list via Set to make sure unique values in list
        const finalTagsList = internalSubmissionUpdates.internalTags 
          ? new Set([...internalSubmissionUpdates.internalTags.split(','), ...associatedTags])
          : new Set(associatedTags);

        const finalTags = Array.from(finalTagsList).map((t: string) => capitalizeSentence(t)).join(',');

        setInternalSubmissionUpdates({ 
          ...internalSubmissionUpdates, 
          internalPrimaryTag: data.suggestionValue,
          internalTags: finalTags
        });
      }
    })
  }

  const handleSuggestedTagsChange = (tags: Tag[]) => {
    const tagNames = new Set(tags.map(t => t.text));

    if(internalSubmissionUpdates) {
      setInternalSubmissionUpdates({ ...internalSubmissionUpdates, internalTags: Array.from(tagNames).join(',') });
    }
  }

  const handleOnDeleteTag = (i: number) => {
    if (internalSubmissionUpdates) {
      const newTags = internalSubmissionUpdates.internalTags.split(',').filter((tag, index) => index !== i);
      setInternalSubmissionUpdates({ ...internalSubmissionUpdates, internalTags: newTags.join(',') });
    }
  }

  const handleOnDeletePrimaryTag = (i: number) => {
    if (internalSubmissionUpdates) {
      setInternalSubmissionUpdates({ ...internalSubmissionUpdates, internalPrimaryTag: '' });
    }
  }

  const selectTagAsPrimaryInternalTag = (tag: string ) => {
    if (internalSubmissionUpdates) {
      onPrimaryTagSelected(null, { suggestionValue: tag });
    }
  }

  const selectTagAsInternalTag = (tag: string ) => {
    if (internalSubmissionUpdates) {
      const newTags = internalSubmissionUpdates.internalTags 
        ? new Set(internalSubmissionUpdates.internalTags.split(','))
        : new Set([]);

      newTags.add(tag);

      setInternalSubmissionUpdates({ ...internalSubmissionUpdates, internalTags: Array.from(newTags).join(',') });
    }
  }

  return (
    <>
      <TabArea style={{
        position: "absolute", 
        top: "-100px", 
        left: "-10px", 
        width: "95%",
        minHeight: "1000px",
        backgroundColor: "#fff",
        zIndex: 9 }}>
        <FilterHeader>
          <AdjustableIconWrapper onClick={closeAdminArtApproval}>
            <HighlightOffIcon />
          </AdjustableIconWrapper>
        </FilterHeader>

        <CenterButtonsWrapper>
          { flipLeft && <IconButton
            href=""
            onClick={onFlipLeft}
            style={nextPreviousBtnStyle}
            disabled={isFlipLeftDisabled}
          >
            <KeyboardArrowLeftIcon /> Previous
          </IconButton>
        }
        { flipRight && 
          <IconButton
            href=""
            onClick={onFlipRight}
            style={nextPreviousBtnStyle}
            disabled={isFlipRightDisabled}
          >
            Next <KeyboardArrowRightIcon />
          </IconButton>
        }
        </CenterButtonsWrapper>
        
        {submission && internalSubmissionUpdates ? (
          <div>
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
              <div>
                <h2 style={{textAlign: "center"}}>ARTIST</h2>
                { submission.artworkType == ArtworkType.classic ? (
                <div style={{width: "300px"}}>
                  <div style={{padding: "10px 0", fontWeight: "bold"}}>Main Color</div>
                  <div style={{display: "flex", flexFlow: "wrap"}}>
                    {colorsList.map((color) => ( 
                      <ColorCheckbox key={color.id}
                        colorInfo={_getColorInfo(color)} 
                        isChecked={submission.mainColor == color.id} 
                        handleColorChange={doNothing} 
                        handleMouseOver={doNothing}/>    
                    ))}
                  </div>
                
                  <div style={{padding: "10px 0", fontWeight: "bold"}}>Additional Colors</div>
                  <div style={{display: "flex", flexFlow: "wrap"}}>
                    {colorsList.map((color) => ( 
                      <ColorCheckbox key={color.id}
                        colorInfo={_getColorInfo(color)}
                        isChecked={submission.additionalColors.indexOf(color.id) > -1}   
                        handleColorChange={doNothing} 
                        handleMouseOver={doNothing}/>      
                    ))}
                  </div>
                </div>
              ) : null}
              </div>
              <div>
                <div style={{textAlign: "center"}}>
                  {submission.artworkType == ArtworkType.classic ? (
                      submission.previewArtInfo && submission.previewArtInfo.artDimension ? (
                        <ArtCanvas 
                          artDimention={submission.previewArtInfo.artDimension} 
                          backgroundColor={canvasBackColorCode}
                          imgSrc={submission.previewArt} 
                        />  
                      ) : ( // Show image for old submissions
                        <PreviewImage
                          src={submission.previewArt}
                          alt="Art Preview"
                          isEnlargeImg={isEnlargeImg}
                          onClick={clickEnlargeImg}
                        /> 
                      ) ) 
                    : artPreviewImg ? // For ArtworkType.allOver
                      <PreviewImage
                          src={artPreviewImg}
                          alt="Art Preview"
                          isEnlargeImg={isEnlargeImg}
                          onClick={clickEnlargeImg}
                        /> 
                    : ( <ArtPreview>
                      <IconContainer>
                        <UploadIcon />
                      </IconContainer>
                      <IconBottomSubtitle style={{ position: "absolute" }}>
                        No Art Was Submitted
                      </IconBottomSubtitle>
                    </ArtPreview>
                  ) }
                  <MainButton
                    type="button"
                    style={{ width: "250px" }}
                    // loaded={artPreviewImg}
                    textAlign="center"
                  >
                    <DownloadLink href={submission.previewArt} download>
                      Download Print File
                    </DownloadLink>
                  </MainButton>
                  
                  
                <ArtFileButtonsWrapper>
                  {isArtFileDeleted ? (
                    <>
                      <MainButton
                        style={{
                          backgroundColor: "lightgrey",
                          cursor: "default",
                          width: "150px",
                        }}
                        disabled
                        textAlign="center"
                      >
                        Download Submitted File
                      </MainButton>
                      <MainButton
                        style={{
                          ...cntrTxtBtnsWithIcons,
                          ...deleteArtFileBtnStyle,
                          cursor: "default",
                        }}
                        disabled
                        textAlign="center"
                      >
                        <DoneIcon style={{ marginRight: "5px" }} /> Art File Deleted
                      </MainButton>
                    </> 
                  ) : (
                    <>
                      <MainButton
                        type="button"
                        style={{ width: "250px" }}
                        textAlign="center"
                      >
                        <DownloadLink
                          href={`${submission.artFile}`}
                          download
                        >
                          {submission.artFile ? "Download Submitted File" : <LoadingIcon />}
                        </DownloadLink>
                      </MainButton>
                    </>
                  )}
                </ArtFileButtonsWrapper>
                </div>
              </div>
              <div>
                <h2 style={{textAlign: "center"}}>ADMIN</h2>
                { submission.artworkType == ArtworkType.classic ? (
                <div style={{width: "300px"}}>
                  <div style={{padding: "10px 0", fontWeight: "bold"}}>Main Color</div>
                  <div style={{display: "flex", flexFlow: "wrap"}}>
                    {colorsList.map((color) => ( 
                      <ColorCheckbox key={color.id}
                        colorInfo={_getColorInfo(color)} 
                        isChecked={submission.internalMainColor == color.id} 
                        handleColorChange={_handleMainColorChange} 
                        handleMouseOver={handleMainColorMouseOver}/>     
                    ))}
                  </div>
                
                  <div style={{padding: "10px 0", fontWeight: "bold"}}>Additional Colors</div>
                  <div style={{display: "flex", flexFlow: "wrap"}}>
                    {colorsList.map((color) => ( 
                      <ColorCheckbox key={color.id}
                        colorInfo={_getColorInfo(color)}
                        isChecked={submission.internalAdditionalColors.indexOf(color.id) > -1}   
                        handleColorChange={_handleAdditionalColorChange}
                        handleMouseOver={handleMainColorMouseOver}/>      
                    ))}
                  </div>
                </div>
              ) : null}
              </div>
            </div>

        <ArtworkContainer style={{marginTop: '100px'}}>
          <SubmitCard>
            <div>
              { submission.isNewArtist == 'YES' ?
                <div style={{marginBottom: '16px'}}>
                  <GreyTextAreaZeroMargin style={{ fontSize: "18px" }}>
                    @{submission.artistName}
                    {submission.isApprovedArtist == 'YES' && <ApprovedFlag />}
                  </GreyTextAreaZeroMargin>
                  <div style={{padding: '5px 12px'}}><NewArtistFlag /></div>
                </div>
              : <GreyTextArea style={{ fontSize: "18px" }}>
                  @{submission.artistName}
                  {submission.isApprovedArtist == 'YES' && <ApprovedFlag />}
                </GreyTextArea>
              }

              <CaptionTitle>Artist Email:</CaptionTitle>
              <GreyTextArea>{submission.artistEmail}</GreyTextArea>

              <CaptionTitle>Title:</CaptionTitle>
              <GreyTextArea>{submission.title}</GreyTextArea>

              <CaptionTitle>Description:</CaptionTitle>
              <GreyTextArea style={{ minHeight: "90px" }}>{submission.description}</GreyTextArea>
              
              <CaptionTitle>Main Inspiration:</CaptionTitle>
              <GreyTextArea>{submission.mainInspiration ? submission.mainInspiration : 'NA'}</GreyTextArea>
              
              <CaptionTitle>Primary Tag:
                <CustomPopover text={PRIMARY_TAG_INFO} />
              </CaptionTitle>
              <DisplayTags tags={submission.primaryTag} handleOnClick={selectTagAsPrimaryInternalTag} />

              <CaptionTitle>Other Tags:
                <CustomPopover text={OTHER_TAG_INFO} />
              </CaptionTitle>
              <DisplayTags tags={submission.tags} handleOnClick={selectTagAsInternalTag} />

              <CaptionTitle>Submitted:</CaptionTitle>
              <GreyTextArea>
                {new Date(submission.createdAt).toLocaleString("en-US", {
                  timeZone: "GMT",
                })}
              </GreyTextArea>
            </div>
            
          </SubmitCard>
          <SubmitCard>
            <div>
              {/* <CaptionTitle>Artist:</CaptionTitle> */}
              { submission.isNewArtist == 'YES' ?
                <div style={{marginBottom: '16px'}}>
                  <GreyTextAreaZeroMargin style={{ fontSize: "18px" }}>
                    @{submission.artistName}
                    {submission.isApprovedArtist == 'YES' && <ApprovedFlag />}
                  </GreyTextAreaZeroMargin>
                  <div style={{padding: '5px 12px'}}><NewArtistFlag /></div>
                </div>
              : <GreyTextArea style={{ fontSize: "18px" }}>
                  @{submission.artistName}
                  {submission.isApprovedArtist == 'YES' && <ApprovedFlag />}
                </GreyTextArea>
              }

              <CaptionTitle>Artist Email:</CaptionTitle>
              <GreyTextArea>{submission.artistEmail}</GreyTextArea>
              
              <CaptionTitle>Title:</CaptionTitle>
              <FormInputTitleEditable
                type="text"
                name="internalTitle"
                label="title"
                placeholder="TITLE"
                data-lpignore="true"
                autoComplete="off"
                handleChange={handleChange}
                value={internalSubmissionUpdates.internalTitle}
                maxlength="180"
                required
              />
              {checkingForDuplicate && <p style={{
                color: '#888', 
                width: '95%', 
                margin: 'auto',
                marginTop: 0, 
                marginBottom: '25px'
                }}>Checking for availability...</p>}

              { !checkingForDuplicate && isTitleExist && <p style={{ 
                color: 'red', 
                width: '95%', 
                margin: 'auto', 
                marginTop: 0, 
                marginBottom: '25px'}}>
                  Error: Title already taken
              </p>}

              <CaptionTitle>Description:</CaptionTitle>
              <TextAreaStyledEditable
                type="text"
                name="internalDescription"
                label="Description"
                placeholder="DESCRIPTION"
                data-lpignore="true"
                autoComplete="off"
                handleChange={handleChange}
                value={internalSubmissionUpdates.internalDescription}
                maxlength="255"
                required
              />
              
              <CaptionTitle>Main Inspiration:</CaptionTitle>
              <FormInputTitleEditable
                type="text"
                name="internalMainInspiration"
                label="Main Inspiration"
                placeholder="Main Inspiration"
                data-lpignore="true"
                autoComplete="off"
                handleChange={handleChange}
                value={internalSubmissionUpdates.internalMainInspiration}
                maxlength="180"
                required
              />

              <CaptionTitle> Primary Tag:
                <CustomPopover text={PRIMARY_TAG_INFO} />
              </CaptionTitle>
              { !internalSubmissionUpdates.internalPrimaryTag && <CustomAutocomplete 
                placeholder="Please enter primary tag"
                onChange={doNothing}
                value={internalSubmissionUpdates.internalPrimaryTag ?? ''}
                onSuggestionSelected={onPrimaryTagSelected}
                onPrimaryTagChange={handlePrimaryTagChange}
                isAdmin={true}
                />
              }
              <DisplayTags 
                tags={internalSubmissionUpdates.internalPrimaryTag} 
                isDeletable={true}
                handleOnDelete={handleOnDeletePrimaryTag}/>

              <CaptionTitle>Other Tags:
                <CustomPopover text={OTHER_TAG_INFO} />
              </CaptionTitle>
              <SuggestedTags 
                onChange={handleSuggestedTagsChange} 
                selectedTags={internalSubmissionUpdates.internalTags ? internalSubmissionUpdates.internalTags.split(",") : []}
                />
              <DisplayTags 
                tags={internalSubmissionUpdates.internalTags} 
                isDeletable={true}
                handleOnDelete={handleOnDeleteTag}/>

              <CaptionTitle>Submitted:</CaptionTitle>
              <GreyTextArea>
                {new Date(submission.createdAt).toLocaleString("en-US", {
                  timeZone: "GMT",
                })}
              </GreyTextArea>

              <CaptionTitle>Schedule Type:</CaptionTitle>

              { userRole === UserRole.ADMIN_VIEW_ONLY ? (
                <GreyTextArea>{submission.scheduleType}</GreyTextArea>
              ) : (
              <GreyTextArea style={{backgroundColor: "#d2edee", padding: "8px 12px"}}>
                <Select
                  id="scheduleTypeSelect"
                  name="scheduleType"
                  value={internalSubmissionUpdates.scheduleType}
                  onChange={handleChange}
                  disableUnderline
                  displayEmpty
                  style={{
                    ...buttonAndTextFontStyle,
                    width: "100%"
                  }}
                >
                  <MenuItem value="" style={buttonAndTextFontStyle}>None</MenuItem>
                    {scheduleTags.map((scheduleTag) => (
                          <MenuItem key={scheduleTag.id} value={scheduleTag.tag_name} style={buttonAndTextFontStyle}>{scheduleTag.tag_name}</MenuItem>
                    ))}
                  
                </Select>
              </GreyTextArea>
              )}

              <CaptionTitle>Creation Type:</CaptionTitle>
              { userRole === UserRole.ADMIN_VIEW_ONLY ? (
                <GreyTextArea>{submission.creationType}</GreyTextArea>
              ) : (
              <GreyTextArea style={{backgroundColor: "#d2edee", padding: "8px 12px"}}>
                <Select
                  name="creationType"
                  value={internalSubmissionUpdates.creationType ?? ''}
                  onChange={handleChange}
                  disableUnderline
                  displayEmpty
                  style={{
                    ...buttonAndTextFontStyle,
                    width: "100%"
                  }}
                >
                  <MenuItem value="" style={buttonAndTextFontStyle}>None</MenuItem>
                  { ProductCreationTypes.map((cType) => (
                    <MenuItem key={cType} value={cType} style={buttonAndTextFontStyle}>{cType}</MenuItem>
                  ))}
                </Select>
              </GreyTextArea>
              )}

              { (internalSubmissionUpdates.creationType == 'ODAD' || internalSubmissionUpdates.creationType == 'WEEKLY') && 
              <>
              <CaptionTitle>Schedule Date:</CaptionTitle>
              <DatePicker
                value={internalSubmissionUpdates.scheduleDate}
                onChange={handleScheduleDateChange}
                fullForm={true}
                />
              </>}

              { internalSubmissionUpdates.creationType == 'ODAD' && 
              <>
                <CaptionTitle>ODAD Position:</CaptionTitle>
                { userRole === UserRole.ADMIN_VIEW_ONLY ? (
                  <GreyTextArea>{submission.odadPosition}</GreyTextArea>
                ) : (
                <GreyTextArea style={{backgroundColor: "#d2edee", padding: "8px 12px"}}>
                  <Select
                    name="odadPosition"
                    value={internalSubmissionUpdates.odadPosition}
                    onChange={handleChange}
                    disableUnderline
                    displayEmpty
                    style={{
                      ...buttonAndTextFontStyle,
                      width: "100%"
                    }}
                  >
                    {['D1', 'D2'].map((pos) => (
                      <MenuItem key={pos} value={pos} style={buttonAndTextFontStyle}>{pos}</MenuItem>
                    ))}
                    
                  </Select>
                </GreyTextArea>
                )}
              </>}

              <div style={{display: "flex", justifyContent: "space-between", marginTop: "10px"}}>
                <div>
                <CaptionTitle>Internal:</CaptionTitle>
                { userRole === UserRole.ADMIN_VIEW_ONLY ? (
                  <GreyTextArea>{internalSubmissionUpdates.status}</GreyTextArea>
                ) : (
                  <GreyTextArea style={{minWidth: "140px", backgroundColor: "#d2edee", padding: "8px 12px"}}>
                    <Select
                      labelId="statusSelect"
                      id="statusSelect"
                      name="status"
                      value={internalSubmissionUpdates.status}
                      onChange={handleChange}
                      disableUnderline
                      style={{
                        ...buttonAndTextFontStyle,
                        width: "100%"
                      }}
                    >
                    {artStatusList.map((artStatus) => (
                          <MenuItem value={artStatus.status_name} style={buttonAndTextFontStyle}>
                            {artStatus.status_name}
                          </MenuItem>
                      ))}
                    </Select>
                  </GreyTextArea>
                )}
                </div>
                <div>
                  <CaptionTitle>External:</CaptionTitle>
                  <GreyTextArea style={{minWidth: "140px"}}>{_getExternalStatusName(internalSubmissionUpdates.status)}</GreyTextArea>
                </div>
              </div>
              <EmailStatus
                style={{ ...emailStatusColor, marginBottom: "20px" }}
              >
                <MailOutlineIcon
                  style={{ marginRight: "5px", fontSize: "12px" }}
                />
                {emailStatus}
              </EmailStatus>
            </div>

            { userRole !== UserRole.ADMIN_VIEW_ONLY ? (
              <CenterButtonsWrapper>
                { userRole !== UserRole.ADMIN_VIEW_ONLY ? (
                  <MainButton
                    onClick={_handleDeleteArtFileClick}
                    style={{
                      ...cntrTxtBtnsWithIcons,
                      ...deleteArtFileBtnStyle,
                    }}
                    textAlign="center"
                  >
                    <DeleteIcon style={{ marginRight: "5px" }} /> Delete Submission
                  </MainButton>
                ) : null }

                { (!isTitleExist && !checkingForDuplicate) && 
                  <MainButton
                    onClick={handleSave}
                    style={{ ...cntrTxtBtnsWithIcons, width: "150px" }}
                    textAlign="center"
                  >
                    <SaveIcon style={{ marginRight: "5px" }} />
                    Save Changes
                  </MainButton>
                }
              </CenterButtonsWrapper>
          ) : null }
          </SubmitCard>
        </ArtworkContainer>

        { userRole !== UserRole.ADMIN_VIEW_ONLY && submission.previewArt ? (
          <EmailTemplate
            id={id}
            artistName={submission.artistName}
            artistEmail={submission.artistEmail}
            title={submission.internalTitle}
            scheduleDate={submission.scheduleDate}
            previewArt={submission.previewArt}
            emailStatusColor={emailStatusColor}
            changeEmailStatusColor={changeEmailStatusColor}
            adminEmail={contactEmail}
            previousEmailStatus={previousEmailStatus}
            previousEmailContent={previousEmailContent}
          />
        ) : (
          ""
        )}
        {submission.artistName ? <LogHistory id={id} /> : ""}
        
        <CenterButtonsWrapper style={{ marginTop: "20px" }}>
          <IconButton
            href=""
            onClick={onFlipLeft}
            style={nextPreviousBtnStyle}
            disabled={isFlipLeftDisabled}
          >
            <KeyboardArrowLeftIcon /> Previous
          </IconButton>
          <IconButton
            href=""
            onClick={onFlipRight}
            style={nextPreviousBtnStyle}
            disabled={isFlipRightDisabled}
          >
            Next <KeyboardArrowRightIcon />
          </IconButton>
        </CenterButtonsWrapper>
        </div>
        ) : <Loader /> }

      </TabArea>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  userAccount: selectUserAccount,
  token: selectUserJWTToken,
  scheduleTags: selectScheduleTags,
  artStatusList: selectStatusList,
  colorsList: selectColors,
  additionalColorsList: selectColors,
});

const mapDispatchToProps = (dispatch: any) => ({
  refreshSettings: () => dispatch(setConfigStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminArtApproval);
