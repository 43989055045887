import React, { Component } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Swal from "sweetalert2";

import { ArtworkType } from "../../constants";
import { SITE_BACKEND_API_URL, SITE_BACKEND_FULL_URL } from "../../server";

import { cleanFileName } from "../../utils";
import { selectArtistProfile } from "../../redux/artist/artist.selector";
import { selectUserJWTToken } from "../../redux/user/user.selector";
import { selectColors } from "../../redux/settings/settings.selector";

import {
  selectSubmissionsErrorAlert,
  selectSubmissionsSuccessAlert,
} from "../../redux/submissions/submissions.selector";

import SuggestedTags from "../Common/suggested-tags.component";
import DisplayTags from "../Common/display-tags.component";

import { Tag } from 'react-tag-input';
import './../Common/suggested-tags-text-styles.css';

import { setConfigStart } from "../../redux/settings/settings.action";
import AllOverPrintExImage from "../../assets/all-over-print-example.jpeg";

import {
  submissionCreateStart,
  submissionErrorAlertClear,
  submissionSuccessAlertClear,
} from "../../redux/submissions/submissions.action";

import { ReactComponent as Upload } from "../../assets/upload.svg";
import { ReactComponent as Loading } from "../../assets/loading.svg";
import { BtnArtSubmit, InputArtPreview } from "../Button";
import { SectionHeading, StyledSelect, StyledMultiSelectCheckbox } from "../FormInput";
import { ArtworkTypeInfoDialog, AllOverPrintExDialog } from "../Dialog";
import { ArtistCanvas } from "../ArtistSubmitArt/artist-canvas";
import { MainButton } from "../Button"
import { ColorsListInterface, Point, Dimension } from '../AdminArtSubmissions/admin-art-submission-types';

import CustomAutocomplete from "../Common/custom-autocomplete.component";
import CustomPopover from "../Common/custom-popover.component";
import { PRIMARY_TAG_INFO, OTHER_TAG_INFO } from "../../constants/text";
import { ODAD_AUTOMATION_API_URL } from "../../server";

import { GetOneSubmissionResponseInterface } from "./../AdminArtSubmissions/admin-art-submission-types";

import {
  SubmissionContainer,
  TabHeader,
  TabTitle,
  TabSubTitle,
  TabSubLink,
  TabArea,
  SubTitle,
  FormArtistSubmit,
  SubmitCard,
  FieldSection,
  ArtPreview,
  IconContainer,
  IconTopSubtitle,
  IconBottomSubtitle,
  PreviewImageWrapper,
  PreviewImage,
  FormInputTitleStyled,
  TextAreaStyled,
  ArtworkTypeWrapper,
  ArtworkTypeOption,
  HelpIconWrapperStyled,
  BottomInstruction,
  BoldSpan,
  CaptionTitle,
} from "../ArtistSubmitArt/artist-submit-art.styles";
import successIcon from "../../assets/success-icon.png";

import { FormControlLabel } from "@material-ui/core";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import axios from "axios";
import ColorCheckbox from "../FormInput/color-checkbox.component";

// const COLORS = axios.get(SITE_BACKEND_API_URL+ '/common/api/getcolors');
const ArtistCertifyText = 'I hereby certify that the submitted artwork has been created by and belongs to me and has not been created using any form of AI generation. I understand that any submission that is determined to have been AI generated is subject to removal and that my account may be restricted or deactivated in these instances.';

interface ArtistEditArtState {
      submissionId: string;
      artistName: string;
      title: string;
      description: string;
      primaryTag: string;
      suggestedTags: string[];
      mainInspiration: string;
      artFileName: string;
      artPreviewImg: string;
      artTrimmedImgURL: string;
      artDimension: Dimension,
      artworkType: string;
      mainColor: number;
      mainColorCode: string;
      canvasBackColorCode: string;
      additionalColors: number[],
      artHasSubmitted: boolean;
      isDisableSubmit: boolean;
      additionalColorsList: ColorsListInterface[];
      isDialogOpen: boolean;
      isAllOverExDialogOpen: boolean;
      openedDialogArtworkType: string;
      stepNumber: 1 | 2 | 3;
      isClarifiedDeclaration: boolean;
      hasNewImage: Boolean;
      duplicateDetectionTimer: NodeJS.Timeout | null,
      isTitleExist: boolean,
      checkingForDuplicate: boolean,
}

interface ArtistSubmitArtProps {
  id: string;
  submissionSuccessMsg: string;
  submissionSuccessAlertClear: Function;
  submissionErrorMsg: string;
  submissionErrorAlertClear: Function;
  setConfigStartAction: Function;
  submissionCreateStart: Function;
  colorsList: ColorsListInterface[];
  artistProfile: {
    artistName: string;
  }
  token: string;
  byAdmin?: boolean;
  artistName?: string;
  onExit: Function;
}

class ArtistSubmissionEdit extends Component<ArtistSubmitArtProps, ArtistEditArtState> {

  private artworkSubmissionForm: React.RefObject<HTMLFormElement>;
  public state: Readonly<ArtistEditArtState>;

  constructor(props: any) {
    super(props);
    
    this.artworkSubmissionForm = React.createRef();

    this.state = {
      submissionId: "",
      artistName: "",
      title: "",
      description: "",
      primaryTag: '',
      suggestedTags: [],
      mainInspiration: "",
      artFileName: "",
      artPreviewImg: "",
      artTrimmedImgURL: "",
      artDimension: { width: 0, height: 0 },
      artworkType: ArtworkType.classic,
      mainColor: 0,
      mainColorCode: "",
      canvasBackColorCode: "",
      additionalColors: [],
      artHasSubmitted: false,
      isDisableSubmit: false,
      // colorsList: [],
      additionalColorsList: [],
      isDialogOpen: false,
      isAllOverExDialogOpen: false,
      openedDialogArtworkType: ArtworkType.classic,
      stepNumber: 3,
      isClarifiedDeclaration: false,
      hasNewImage: false,
      duplicateDetectionTimer: null,
      isTitleExist: false,
      checkingForDuplicate: false,
    };

  }

  static getDerivedStateFromProps(props: ArtistSubmitArtProps) {
    const {
      artistProfile: { artistName },
    } = props;

    return {
      artistName,
    };
  }

  componentDidMount() {
      // COLORS.then((res) => {
      //   const colorsList = res.data.map((c: any) => { 
      //     c.id = parseInt(c.id);
      //     return c;
      //   });
      //   this.setState({colorsList, additionalColorsList: colorsList });
      // });

      // this.setState({colorsList, additionalColorsList: colorsList });

      const { setConfigStartAction } = this.props;

      // setConfigStartAction();

      const { token, id } = this.props;

     axios.get<GetOneSubmissionResponseInterface>(`/api/artist/submissions/review/${id}`, { 
        headers: {
          Authorization: `JWT ${token}`,
        },
      }).then((res) => {
        const { id, previewArt, title, description, mainInspiration, primaryTag, tags, mainColor, additionalColors } = res.data.submissionDetails;

        const { colorsList } = this.props;

        const mainColorData = colorsList.filter(color => color['id'] == mainColor);

        const mainColorCode = "#"+mainColorData[0].color_code;

        const artTrimmedImgURL = SITE_BACKEND_FULL_URL.replace('/backend', '/') + previewArt.replace('/api/', '');
        this.setState({
          submissionId: id.toString(),
          artTrimmedImgURL,
          title,
          description,
          mainInspiration,
          primaryTag,
          suggestedTags: tags.split(','),
          mainColor,
          additionalColors,
          mainColorCode,
          canvasBackColorCode: mainColorCode,
        });
      });

      // console.log(submissionDetails);
  
  }

  componentDidUpdate() {
    this._submissionMessages();
  }

  handleChange = (event: any) => {
    const { name, value } = event.target as { name: string; value: string };

    if (name == 'title') {

      this.setState({ checkingForDuplicate: true });

      const { duplicateDetectionTimer } = this.state;

      if (duplicateDetectionTimer) {
        clearTimeout(duplicateDetectionTimer);
      }
  
      const timer = setTimeout(async () => {
        // Check if title is already taken
        const {
          data
        } = await axios.get("/api/artist/is-art-title-exist?title="+encodeURIComponent(value.trim()), {
          headers: {
            Authorization: `JWT ${this.props.token}`,
          },
        });
  
        if (data.status === 'TITLE_EXIST') {
          this.setState({ isTitleExist: true });
        } else {
          this.setState({ isTitleExist: false });
        }

        this.setState({ checkingForDuplicate: false });
  
      }, 2000);
  
      this.setState({ duplicateDetectionTimer: timer })
    }

    // console.log(name);

    let params = { [name]: value };
    this.setState({ ...params, isDisableSubmit: false });      

    // console.log(this.state);
  };

  handleArtDimentionChange = (artDimension: Dimension) => {
    this.setState({ artDimension });  
  }
  
  handleMainColorChange = (colorId: number) => {
      
    const { additionalColors, mainColor } = this.state;
    const { colorsList } = this.props;

    // Check if deselect
    if(mainColor == colorId) {
        this.setState({ 
          mainColor : 0, 
          mainColorCode: '',
          canvasBackColorCode: '',
        });
    } else {
    
      // Remove main color from additionalColors list
      const updatedAdditionalColorsList = colorsList.filter(color => color['id'] !== colorId);
      const updatedAdditionalColors = additionalColors.filter(color => color !== colorId);

      const mainColorData = colorsList.filter(color => color['id'] == colorId);

      this.setState({ 
          mainColor : colorId, 
          mainColorCode: "#"+mainColorData[0].color_code,
          canvasBackColorCode: "#"+mainColorData[0].color_code,
          additionalColorsList: updatedAdditionalColorsList,
          additionalColors: updatedAdditionalColors
      });
    }
  };
  
    openDiaog = (e: any, artworkType: string) => {
        // const { artworkType } = this.state;
        this.setState({isDialogOpen: true, openedDialogArtworkType: artworkType});
        e.stopPropagation();
    }
    
    closeDiaog = () => {
        this.setState({isDialogOpen: false});
    }
  
  handleAdditionalColorChange = (colorId: number) => {
    
    const { additionalColors, mainColor } = this.state;
  
    if(additionalColors.includes(colorId)) {
      // Remove
      const newColors = additionalColors.filter(c => c != colorId);
      this.setState({ additionalColors: newColors });
    } else {
      // Allowed to select only 4 colors
      if(additionalColors.length == 4) {
        Swal.fire({
          icon: "error",
          text:
            "Sorry you can select maximum 4 colors only.",
          showConfirmButton: true,
        });
      } else if(colorId == mainColor) { // check if main color is already selected
        Swal.fire({
          icon: "error",
          text:
            "Main color should not be part of additional color.",
          showConfirmButton: true,
        });
      } else {
        // include
        additionalColors.push(colorId);
        this.setState({ additionalColors });
      }
    }
  };

  handleMainColorMouseOver = (colorHash: string, type: 'IN'|'OUT') => {
    if(type == 'IN') {
      this.setState({ canvasBackColorCode: colorHash });
    } else {
      const { mainColorCode } = this.state;
      this.setState({ canvasBackColorCode: mainColorCode });
    }
  }
  
  handleArtTypeChange = async (type: string) => {
      this.setState({ artworkType: type });
  }
  
  handleSubmit = (event: any) => {
    
    event.preventDefault();
    // console.log("handleSubmit");
    // this.setState({ canvasImageData });

    // Todo: form validation is not working here

    if (this.state.isTitleExist) {
      Swal.fire({
        icon: "error",
        text:
          "Title already taken.",
        showConfirmButton: true,
      });
      return;
    }

    this._submitArtwork();


    this.setState({
      isDisableSubmit: true,
    });
  };

  handleExit = () => {

    console.log('handleEditExit 1: ')

    this._resetForm();
    this.props.onExit();
    // this.setState({ 
    //     mainColor: 0,
    //     mainColorCode: "",
    //     canvasBackColorCode: "",
    //     additionalColors: [],
    //     stepNumber: 1, 
    //     artPreviewImg: ""
    // });

    
  }

  handleRevertToOriginal = () => {
    this.setState({ 
      mainColor: 0,
      mainColorCode: "",
      canvasBackColorCode: "",
      additionalColors: [],
  });
  }

  handleChangeImage = () => {
    this.setState({stepNumber: 1, artPreviewImg: '', artTrimmedImgURL: ''});
  }

  onChangeArtFile = async (event: any) => {

    console.log('onChangeArtFile --> 1');

    let [file] = event.target.files;
    if (!file) return;
    
    console.log(file.name);
    
    const { artworkType } = this.state;
    
    const fileExtention = file.name.split('.').pop();
    const expectedFileExtentions = (artworkType === ArtworkType.classic) ? ['png'] : ['jpg','jpeg'];
//    const expectedFileExtentionNames = (artworkType === ArtworkType.classic) ? ['psd, ai OR png'] : ['ai, jpeg, png OR psd'];
    
    if(!expectedFileExtentions.includes(fileExtention.toLowerCase())) {
        Swal.fire({
            icon: "error",
            text:
    //          "Art file type must be "+expectedFileExtentionNames+".",
                "Does not meet requirements. See below for file types accepted.",
            showConfirmButton: true,
        });
        
        event.target.value = null;

      return;
    }
    
    // Make sure `file.size` does not 0 size
    if (file.size < 5) {
      Swal.fire({
        icon: "error",
        text: "Sorry your file is too small! Please make sure your image size is greater than 0MB.",
        showConfirmButton: true,
      });
      
      event.target.value = null;

      return;
    }
    
//    console.log(file.size);
    
    // Make sure `file.size` does not exceed 15MB
    if (file.size > 15000000) {
      Swal.fire({
        icon: "error",
        text:
          "Sorry your file is too large! Please limit your image size to less than 15MB.",
        showConfirmButton: true,
      });
      
      // event.target.value = null;

      return;
    }

    // Check the image size

    if(artworkType == ArtworkType.classic) {
      // Send to trim image
      this._trimImage(file.size);
    }

    const artPreviewDetails = await this._generatePreviewImg(file) as { data: string, dimension: Dimension };
    const { width, height } = artPreviewDetails.dimension;

    // Validate dimention as 60x100 
    if(artworkType == ArtworkType.allOver) {

        if(width/height != 0.6) {  // ==> 60 / 100 = 0.6

          Swal.fire({
            icon: "error",
            text: "A JPEG file of 60 inches by 100 inches is required for submission.",
            showConfirmButton: true,
          });

          // event.target.value = null;

          return;
        }

        this.setState({ artTrimmedImgURL: artPreviewDetails.data });  
    }

    // const canvasHeight = (height / width) * fixedCanvasWidth;
    this.setState({ artPreviewImg: artPreviewDetails.data, isDisableSubmit: false, hasNewImage: true });
  };


  _submitArtwork = async () => {

    console.log('_submitArtwork');

    const { hasNewImage } = this.state;

    if (!hasNewImage) {

      const { hasNewImage, submissionId, title, description, mainInspiration, mainColor, additionalColors, suggestedTags, primaryTag } = this.state;

      const { token } = this.props;

      try {
        await axios.put(
          `/api/artist/submissions/update/${submissionId}`,
          { id: submissionId, title, description, mainInspiration, primaryTag, suggestedTags, mainColor, additionalColors },
          {
            headers: { Authorization: `JWT ${token}` },
          }
        );

        Swal.fire({
          icon: "success",
          title: "Successfully saved your changes!",
        });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Something went wrong. Please try again.",
        });
      }
    } else {

      // console.log('has new image')

      if(!this.artworkSubmissionForm.current) {
        return;
      }

      const { hasNewImage, submissionId, title, description, mainInspiration, 
        mainColor, additionalColors, suggestedTags, primaryTag,
        artworkType, artTrimmedImgURL, artDimension } = this.state;

      const { elements } = this.artworkSubmissionForm.current;

      if (artworkType == ArtworkType.classic && (!mainColor || mainColor === 0)) {
        Swal.fire({
          icon: "error",
          text: 'Please Choose Main Color!',
        });
        return true;
      }

      if (!artTrimmedImgURL) {
        Swal.fire({
          icon: "error",
          text: 'Please wait until art is being processed!',
        });
        return true;
      }

      const inputsDOM = Array.from(elements) as HTMLInputElement[];

      const formData = new FormData();

      inputsDOM.forEach((el) => {
        console.log(el);

        const { files, name, value } = el ;
        if (files) {
          console.log("Files found: ");
          console.log(files);
          formData.append(name, files[0]);
        } else if (value) {
          console.log(name);
          formData.append(name, value);
        }
      });

      formData.append('id', submissionId);
      formData.append('primaryTag', primaryTag);
      formData.append('suggestedTags', suggestedTags.join(','));
      formData.append('previewArtInfo', JSON.stringify({ artDimension }));
      formData.append('mainColor', mainColor.toString());
      formData.append('additionalColors', JSON.stringify(additionalColors.map(c => parseInt(c.toString())))); // Save list of integers

      if (artworkType == ArtworkType.classic) {
        formData.append('artTrimmedImgURL', artTrimmedImgURL);
      }

      if(this.props.byAdmin && this.props.artistName) {
        formData.append('artistName', this.props.artistName);
      }

      console.log('formData: ');
      console.log(formData);
      // return false;

      const { token } = this.props;

      Swal.fire({
        icon: "warning",
        text:
          "Please be patient!!! Allow 1 - 3 minutes for upload. Don't close this window these are large files.",
        showConfirmButton: false,
      });

      const {
        data: { submissionDetails },
      } = await axios.post("/api/artist/update-artwork", formData, {
        headers: {
          // "content-type": "multipart/form-data",
          Authorization: `JWT ${token}`,
        },
      });

      Swal.fire({
        icon: "success",
        title: "Successfully saved your changes!",
      });

      this.setState({ artHasSubmitted: true });
    }
  };

  _trimImage = async (fileSize: number) => {

    console.log("fileSize: ");
    console.log(fileSize);

    if(this.artworkSubmissionForm.current) {
      const { elements } = this.artworkSubmissionForm.current;

      const inputsDOM = Array.from(elements) as HTMLInputElement[];
      const formData = new FormData();

      inputsDOM.forEach((el) => {
        const { files, name, value } = el ;
        if (files) {
          formData.append(name, files[0]);
        }
      });

      const { token } = this.props;

      try {
        const {
          data: { path },
        } = await axios.post("/api/artist/trim-artwork", formData, {
          headers: {
            Authorization: `JWT ${token}`,
          },
        });

        console.log('Path: '+path);

        this.setState({ artTrimmedImgURL: path });  
          
      } catch (error: any) {
        Swal.fire({
          icon: "error",
          title: error.response.data.error ?? "Something went wrong. Please try again.",
        });

        this._resetForm();
      }
    }
  }

  _generatePreviewImg = (file: Blob) => {
    return new Promise((resolve, reject) => {
      const loadImg = () => {
        reader.removeEventListener("load", loadImg);
        reader.removeEventListener("error", loadError);

        const image = new Image();
        image.src = reader.result as string;

        image.onload = function() {
            // access image size here 
            const dimension = { width: image.width, height: image.height } as Dimension;

            resolve({ data: reader.result, dimension });
        };
      };

      const loadError = (event: any) => {
        reader.removeEventListener("load", loadImg);
        reader.removeEventListener("error", loadError);
        reject(event);
      };

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener("load", loadImg);
      reader.addEventListener("error", loadError);
    });
  };

  _resetForm = () => {
    const {
      artistProfile: { artistName },
    } = this.props;

    if(this.artworkSubmissionForm.current) {
      this.artworkSubmissionForm.current.reset();
    }

    this.setState({
      artistName,
      title: "",
      description: "",
      mainInspiration: "",
      suggestedTags: [],
      artFileName: "",
      artPreviewImg: "",
      artTrimmedImgURL: "",
      artworkType: ArtworkType.classic,
      mainColor: 0,
      mainColorCode: "",
      canvasBackColorCode:  "",
      additionalColors: [],
      artHasSubmitted: false,
      isDisableSubmit: false,
      stepNumber: 3,
      isClarifiedDeclaration: false,
      hasNewImage: false,
      checkingForDuplicate: false,
      isTitleExist: false,
      duplicateDetectionTimer: null,
    });
  };

  clarifyDeclaration = (e: any, checked: boolean) => {
    this.setState({ isClarifiedDeclaration: checked })
  };

  handleClarifyDeclaration = () => {
    this.setState({ isClarifiedDeclaration: !this.state.isClarifiedDeclaration })
  };

  _submissionMessages() {
    // Check Redux for messages

    const {
      submissionSuccessMsg,
      submissionSuccessAlertClear,
      submissionErrorMsg,
      submissionErrorAlertClear,
    } = this.props;

    if (!!submissionSuccessMsg) {
      // Swal.fire({
      //   icon: "success",
      //   text: submissionSuccessMsg,
      //   showConfirmButton: false,
      // });

      Swal.fire({
        showConfirmButton: false,
        html: `<h1>${submissionSuccessMsg}</h1>
        <div>
          <img src="${successIcon}" style="height: 80px; width: 80px"/>
        </div>`
      });

      setTimeout(() => {
        submissionSuccessAlertClear();
        this._resetForm();
      }, 2000);
    } else if (!!submissionErrorMsg) {
      Swal.fire({
        icon: "error",
        text: submissionErrorMsg,
        showConfirmButton: false,
      });

      setTimeout(() => {
        submissionErrorAlertClear();
        this.setState({ artHasSubmitted: false });
      }, 2000);
    }
  }

  _nextStep = () => {
    this.setState({stepNumber: 3});
  }

  _getColorInfo = (color: ColorsListInterface) => {
    return {
      colorId: color.id, 
      colorCode: color.color_code, 
      colorName: color.color_name,
      isLightColor: color.is_light_color
    }
  }

  _openAllOverEx = (e: any) => {
    console.log('_openAllOverEx');
    this.setState({ isAllOverExDialogOpen: true})
  }

  _handleSuggestedTagsChange = (tags: Tag[]) => {
    const tagNames = tags.map(t => t.text);
    this.setState({ suggestedTags: tagNames })
  }

  _handleOnDeleteTag = (i: number) => {
      const newTags = this.state.suggestedTags.filter((tag, index) => index !== i);
      this.setState({ suggestedTags: newTags });
  }

  _onPrimaryTagSelected = (event: any, data: any) => {

    // Fetch associated tags
    fetch(ODAD_AUTOMATION_API_URL+'/getAssociatedTags?primary_tag='+data.suggestionValue)
    .then(res => res.json())
    .then(res => {
      this.setState({ primaryTag: data.suggestionValue, suggestedTags: res.tags })
    })
  }

  _handlePrimaryTagChange = (tag: string) => {
    this.setState({ primaryTag: tag })
  }

  _handleOnDeletePrimaryTag = () => {
    this.setState({ primaryTag: '' })
  }

  render() {
    const {
      artistName,
      title,
      description,
      primaryTag,
      suggestedTags,
      mainInspiration,
      isDisableSubmit,
      artPreviewImg,
      artHasSubmitted,
      artFileName,
      artworkType,
      mainColor,
      mainColorCode,
      canvasBackColorCode,
      additionalColors,
      // colorsList,
      additionalColorsList,
      isDialogOpen,
      openedDialogArtworkType,
      stepNumber,
      isAllOverExDialogOpen,
      isClarifiedDeclaration,
      isTitleExist,
      checkingForDuplicate,
    } = this.state;

    const { colorsList } = this.props;

    return (
        <div style={{position: 'relative', paddingBottom: '50px'}}>
          <SubTitle>EDIT YOUR ARTWORK</SubTitle>
          <FormArtistSubmit
            onSubmit={this.handleSubmit}
            ref={this.artworkSubmissionForm}
          >
            <input type="hidden" name="artworkType" value={artworkType}/>
            
            { stepNumber < 3 ? (
              <ArtworkTypeWrapper>
                  <ArtworkTypeOption onClick={() => this.handleArtTypeChange(ArtworkType.classic)} className={['left', ( artworkType === ArtworkType.classic ? 'active' : '' )]}>
                      <div style={{display: "flex", justifyContent: "center"}}>
                          <div>Classic Artwork</div>
                          <div><HelpOutlineIcon onClick={(e) => this.openDiaog(e, ArtworkType.classic)} style={{marginLeft: "10px"}} /></div>
                      </div>
                  </ArtworkTypeOption>
                  <ArtworkTypeOption onClick={() => this.handleArtTypeChange(ArtworkType.allOver)} className={['right', ( artworkType === ArtworkType.allOver ? 'active' : '' )]}>
                      <div style={{display: "flex", justifyContent: "center"}}>
                          <div>All Over/Patterned Artwork</div>
                          <div><HelpOutlineIcon onClick={(e) => this.openDiaog(e, ArtworkType.allOver)} style={{marginLeft: "10px"}} /></div>
                      </div>
                  </ArtworkTypeOption>
              </ArtworkTypeWrapper>
            ) : null }
            
            <ArtworkTypeInfoDialog artworkType={openedDialogArtworkType} isOpen={isDialogOpen}  handleClose={this.closeDiaog} />
        
            <SubmitCard>
            <FieldSection>
              
              <div style={{display: (stepNumber < 2) ? 'block': 'none'}}>
                <PreviewImageWrapper style={{display: artPreviewImg ? 'block' : 'none', textAlign: "center"}}>
                    <PreviewImage style={{maxHeight: "250px"}}
                      src={artPreviewImg}
                      alt="Art Preview"
                    />
                </PreviewImageWrapper>
                  <label htmlFor="preview-art" style={{display: artPreviewImg ? 'none' : 'block'}}>
                    <InputArtPreview
                      id="preview-art"
                      type="file"
                      name="artFile"
                      onChange={this.onChangeArtFile}
                      textAlign="center"
                      accept={(artworkType == ArtworkType.classic) ? 'image/png' : 'image/jpeg'}
                    >
                        <ArtPreview>
                          <IconContainer>
                            <Upload />
                          </IconContainer>
                          <IconTopSubtitle>Click to upload images</IconTopSubtitle>
                        </ArtPreview>
                    </InputArtPreview>
                  </label>

                  <BottomInstruction>
                    { artworkType == ArtworkType.classic ?
                      (<div>
                        <p className="heading">File Requirements</p>
                        <div>Please submit a <BoldSpan>transparent PNG file</BoldSpan> at a minimum of <BoldSpan>150 dpi</BoldSpan> and a maximum of <BoldSpan>300 dpi</BoldSpan>.</div>
                        <div>Artwork should fit within a 12” x 16” artboard.</div>
                        <div>Max file size is 15MB.</div>
                        <div style={{ marginTop: "10px" }}>If you have any questions or concerns about the submission process, please contact us at <a href="mailto:art@teefury.com">art@teefury.com</a> for assistance.</div>
                      </div>) : 
                      (<div>
                        <p className="heading">File Requirements</p>
                        <div>Please submit a <BoldSpan>high resolution, seamless pattern as a JPEG</BoldSpan> file at <BoldSpan>72 dpi</BoldSpan>.</div>
                        <div>Required dimensions are 60”x 100”.</div>
                        <div>Max file size is 15MB.</div>
                        <div style={{ marginTop: "10px" }}>If you have any questions or concerns about the submission process, please contact us at <a href="mailto:art@teefury.com">art@teefury.com</a> for assistance.</div>
                      </div>)
                    }
                  </BottomInstruction>
                </div>
                
                <div style={{display: (stepNumber >= 2) ? 'flex': 'none', justifyContent: "space-between", marginTop: "30px"}}>
                  <ArtistCanvas 
                    artworkType={artworkType} 
                    imgSrc={this.state.artTrimmedImgURL} 
                    backgroundColor={canvasBackColorCode} 
                    handleArtDimentionChange={this.handleArtDimentionChange}
                    handleExit={this.handleExit}
                    handleRevertToOriginal={this.handleRevertToOriginal}
                    isEdit={true}
                    onChangeImage={this.handleChangeImage}
                    isHideUpdateButton={checkingForDuplicate || isTitleExist}
                    /> 
                  <div style={{width: "45%"}} className="artist-side">
                    <FormInputTitleStyled
                      type="text"
                      name="artistName"
                      label="artist_name"
                      data-lpignore="true"
                      value={this.props.artistName ?? artistName}
                      readOnly
                    />
                    <FormInputTitleStyled
                      type="text"
                      name="title"
                      label="title"
                      placeholder="Title *"
                      data-lpignore="true"
                      autoComplete="off"
                      handleChange={this.handleChange}
                      value={title}
                      maxlength="180"
                      required
                    />

                    {checkingForDuplicate && <p style={{
                      color: '#888', 
                      width: '75%', 
                      margin: 'auto',
                      marginTop: 0, 
                      marginBottom: '25px'
                      }}>Checking for availability...</p>}

                    { !checkingForDuplicate && isTitleExist && <p style={{ 
                      color: 'red', 
                      width: '75%', 
                      margin: 'auto', 
                      marginTop: 0, 
                      marginBottom: '25px'}}>
                        Error: Title already taken
                    </p>}

                    <TextAreaStyled
                      type="text"
                      name="description"
                      label="Description"
                      placeholder="Description *"
                      data-lpignore="true"
                      autoComplete="off"
                      handleChange={this.handleChange}
                      value={description}
                      maxlength="255"
                      required
                    />

                  <FormInputTitleStyled
                      type="text"
                      name="mainInspiration"
                      label="Main Inspiration"
                      placeholder="Main Inspiration *"
                      data-lpignore="true"
                      autoComplete="off"
                      handleChange={this.handleChange}
                      value={mainInspiration}
                      maxlength="180"
                      required
                    />

                    <CaptionTitle> Primary Tag:
                      <CustomPopover text={PRIMARY_TAG_INFO} />
                    </CaptionTitle>
                    {!primaryTag && <CustomAutocomplete 
                      placeholder="Please enter primary tag"
                      onChange={() => {}}
                      value={primaryTag}
                      onSuggestionSelected={this._onPrimaryTagSelected}
                      isAdmin={false}
                      onPrimaryTagChange={this._handlePrimaryTagChange}
                      />
                    }
                    <DisplayTags 
                      tags={primaryTag} 
                      isDeletable={true}
                      handleOnDelete={this._handleOnDeletePrimaryTag}/>

                    <CaptionTitle>Other Tags:
                      <CustomPopover text={OTHER_TAG_INFO} />
                    </CaptionTitle>
                    <SuggestedTags 
                      onChange={this._handleSuggestedTagsChange} 
                      selectedTags={suggestedTags}
                    />
                    <DisplayTags 
                      tags={suggestedTags.join(',')} 
                      isDeletable={true}
                      handleOnDelete={this._handleOnDeleteTag}/>

                    { artworkType == ArtworkType.classic ? (
                      <div style={{width: "80%", margin: "auto"}}>
                        <div style={{padding: "10px 0", fontWeight: "bold", marginTop: "10px"}}>Choose Main Color</div>
                        <div style={{display: "flex", flexFlow: "wrap"}}>
                          {colorsList.map((color) => ( 
                            <ColorCheckbox key={color.id}
                              colorInfo={this._getColorInfo(color)} 
                              isChecked={mainColor == color.id} 
                              handleColorChange={this.handleMainColorChange}    
                              handleMouseOver={this.handleMainColorMouseOver} />
                          ))}
                        </div>
                      
                        <div style={{padding: "10px 0", fontWeight: "bold", marginTop: "20px"}}>Choose Additional Colors (4 Max)</div>
                        <div style={{display: "flex", flexFlow: "wrap"}}>
                          {colorsList.map((color) => ( 
                            <ColorCheckbox key={color.id}
                              colorInfo={this._getColorInfo(color)}
                              isChecked={additionalColors.indexOf(color.id) > -1}   
                              handleColorChange={this.handleAdditionalColorChange} 
                              handleMouseOver={this.handleMainColorMouseOver}/>     
                          ))}
                        </div>
                      </div>
                    ) : null}

                      <div style={{margin: "30px auto", width: "80%"}}>
                        <FormControlLabel 
                          control={
                            <StyledMultiSelectCheckbox 
                              checked={ isClarifiedDeclaration } 
                              required={true}
                              onInvalid={e => (e.target as HTMLInputElement).setCustomValidity('Please read and verify the submission terms.')} 
                              onInput={e => (e.target as HTMLInputElement).setCustomValidity('')}
                            />
                          } 
                          onChange={ this.clarifyDeclaration } 
                          label=""/>
                        <label style={{cursor: 'pointer', lineHeight: "26px"}} onClick={ this.handleClarifyDeclaration }>
                          * { ArtistCertifyText }
                        </label>
                      </div>

                      <div style={{margin: "10px auto 50px auto", width: "80%"}}> * Required Fields</div>

                      { artworkType == ArtworkType.allOver && 
                        <div style={{margin: "10px auto 50px auto", width: "80%"}}>
                          <a href="javascript:void(0)" onClick={this._openAllOverEx}>Click here</a> to see an example of all over print.
                        </div>
                      } 

                      <AllOverPrintExDialog 
                        isOpen={isAllOverExDialogOpen} 
                        handleClose={() => this.setState({ isAllOverExDialogOpen: false})} 
                        imageDataUrl={AllOverPrintExImage}
                        />

                  </div>
                </div>            
              </FieldSection>
            </SubmitCard>

            {artPreviewImg || 1 ? (
              stepNumber == 3 ? (
                null
              ) : (
                <MainButton type="button" textAlign="right" style={{width: "140px" }} onClick={this._nextStep}>Continue</MainButton>
              )
            ) : (
              <MainButton type="button" textAlign="right" style={{width: "140px", backgroundColor: "lightgray", cursor: "default" }}>Continue</MainButton>
            )}
          </FormArtistSubmit>
        </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  artistProfile: selectArtistProfile,
  submissionErrorMsg: selectSubmissionsErrorAlert,
  submissionSuccessMsg: selectSubmissionsSuccessAlert,
  token: selectUserJWTToken,
  colorsList: selectColors,
});

const mapDispatchToProps = (dispatch: any) => ({
  submissionCreateStart: (formData: any) =>
    dispatch(submissionCreateStart({ formData })),
  submissionErrorAlertClear: () => dispatch(submissionErrorAlertClear()),
  submissionSuccessAlertClear: () => dispatch(submissionSuccessAlertClear()),
  setConfigStartAction: () => dispatch(setConfigStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArtistSubmissionEdit);
