import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";

import { selectUserAccount } from "../../redux/user/user.selector";
import {
  updateUserStart,
  clearUserError,
  updateUserFailure,
  logoutStart,
} from "../../redux/user/user.action";
import { selectArtistProfile } from "../../redux/artist/artist.selector";
import {
  getArtistProfileStart,
  updateArtistProfileStart,
  clearArtistErrors,
  artistProfileFailure,
} from "../../redux/artist/artist.action";

import { setConfigStart } from "../../redux/settings/settings.action";


import ArtistHomeTopCommissions from "./artist-home-top-commissions-table.component";
import ArtistHomeLifetimeCommissions from "./artist-home-lifetime-commissions-table.component";
import ArtistHomeSubmissions from "./artist-home-submissions-table";


class ArtistHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tableData: [],
      errorMsg: "",
      loading: true,
      startDate: new Date(),
      endDate: new Date(),
      isOpenViewArt: false,
    };
  }
  static getDerivedStateFromProps(props) {
    const { userAccount } = props;
    return {
      contactEmail:
        userAccount && userAccount.contactEmail ? userAccount.contactEmail : "",
    };
  }

  componentDidMount() {
    const { userAccount, artistProfile, setConfigStartAction, history, logOut } = this.props;
    if (this._redirectUser(userAccount, artistProfile)) {
      this.setState({ ...artistProfile });
    }

    // Force logout user by condition
    if (!userAccount.clientAppVersion || userAccount.clientAppVersion < 5.9) {
      // Force logout user to use latest version (Refresh cache)
      logOut();
      history.push("/");
    } else {
      console.log('Using client app version: '+userAccount.clientAppVersion);

      // setConfigStartAction();
    }
  }


  shouldComponentUpdate(nextProps) {
    const { userAccount, artistProfile } = nextProps;
    return this._redirectUser(userAccount, artistProfile);
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      hasArtistFromSaved: false,
      isDisableArtistSubmit: false,
      hasUserFormSaved: false,
      isDisableUserSubmit: false,
    });
  };
  
  handleOpenViewArt = (isOpen) => {
    this.setState({isOpenViewArt : isOpen});
  }

  _redirectUser = (userAccount, artistProfile) => {
    const { history, logOut } = this.props;
    const hasCreatedUserAccount =
      userAccount && userAccount.contactEmail ? true : false;
    const hasCreatedArtistProfile =
      artistProfile && artistProfile.artistName ? true : false;

    if (hasCreatedUserAccount) {
      if (hasCreatedArtistProfile) {
        return true;
      } else {
        history.push("/artist/create");
        return false;
      }
    } else {
      logOut();
      history.push("/");
      return false;
    }
  };

  render() {
    const {
      // Artist Name
      artistName,
      isOpenViewArt,
    } = this.state;
    return (
      <section>
        <h1 style={{ color: "#6CB6BB", padding: "0 20px" }}>Welcome {artistName}</h1>

        <div style={{ maxWidth: "1600px", padding: "0 20px" }}>
        
            <div style={{display: "flex", justifyContent: "space-between"}}>
                {/* flex to make equal height left and right */}
                <div style={{width: "49%", display: isOpenViewArt ? "none" : "flex", flexDirection: 'column', justifyContent: 'space-between'}}>
                    <h4>TOP PERFORMING PRODUCTS</h4>
                    <ArtistHomeTopCommissions />
                </div>

                {/* flex to make equal height left and right */}
                <div style={{width: isOpenViewArt ? "100%" : "50%", display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                    <h4>RECENT SUBMISSIONS</h4>
                    <ArtistHomeSubmissions openViewArtHandler={this.handleOpenViewArt} />
                </div>
            </div>
        
            <div style={{marginTop: '50px'}}>
                <h4 style={{margin: "auto"}}>COMMISSIONS SUMMARY</h4>
                <ArtistHomeLifetimeCommissions />
            </div>
            
        </div>

      </section>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userAccount: selectUserAccount,
  artistProfile: selectArtistProfile,
});

const mapDispatchToProps = (dispatch) => ({
  getArtistProfileStart: () => dispatch(getArtistProfileStart()),
  updateArtistProfileStart: (reqBody) =>
    dispatch(updateArtistProfileStart({ reqBody })),
  clearReduxArtistErrors: () => dispatch(clearArtistErrors()),
  updateUserStart: (reqBody) => dispatch(updateUserStart({ reqBody })),
  clearReduxUserErrors: () => dispatch(clearUserError()),
  updateUserFailure: ({ ...errObj }) =>
    dispatch(updateUserFailure({ ...errObj })),
  artistErrorMsg: ({ ...errObj }) =>
    dispatch(artistProfileFailure({ ...errObj })),
  logOut: () => dispatch(logoutStart()),
  setConfigStartAction: () => dispatch(setConfigStart()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ArtistHome)
);